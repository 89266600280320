import {api} from "./api";
import {ICrudResponse, IDeviceAppearance, IQueryParams} from "./types";

export const deviceAppearanceApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeviceAppearances: build.query<{ content: IDeviceAppearance[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/deviceAppearance/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceAppearance', tag}],
        }),
        createDeviceAppearance: build.mutation<ICrudResponse, IDeviceAppearance>({
            query: (payload) => ({
                url: '/api/deviceAppearance/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceAppearance'}]
        }),
        updateDeviceAppearance: build.mutation<ICrudResponse, IDeviceAppearance>({
            query: (payload) => ({
                url: '/api/deviceAppearance/update',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceAppearance'}]
        }),
        readDeviceAppearance: build.query<IDeviceAppearance, { _id?: string }>({
            query: (params) => ({
                url: `/api/deviceAppearance/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceAppearance', tag}],
        }),
        removeDeviceAppearance: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/deviceAppearance/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceAppearance'}]
        })
    })
})

export const {
    useCreateDeviceAppearanceMutation,
    useUpdateDeviceAppearanceMutation,
    useRemoveDeviceAppearanceMutation,
    useReadDeviceAppearanceQuery,
    useGetDeviceAppearancesQuery,
} = deviceAppearanceApi