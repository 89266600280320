import React from "react";
import {Link, useLocation} from "react-router-dom";
import {NavLink, createStyles, getStylesRef} from "@mantine/core";

import {
    IconMoneybag,
    IconHome,
    IconBrowser,
    IconBrandGoogleAnalytics,
    IconHexagonLetterA,
    IconUserCircle,
    IconUser,
    IconDotsCircleHorizontal,
    IconBriefcase,
    IconLayoutGrid,
    IconBuildingSkyscraper,
    IconLayersIntersect,
    IconDeviceLaptop,
    IconFriends,
    IconZoomQuestion,
    IconFloatRight,
    IconCategory,
    IconDots,
    IconGitBranchDeleted, IconZoomMoney
} from '@tabler/icons-react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {toggleSideBar} from "../../app/app.slice";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    routerLink: {
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        fontWeight: 400,
    },
    link: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        fontWeight: 400,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,

            [`& .${getStylesRef('icon')}`]: {
                color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            },
        },
    },

    linkIcon: {
        ref: getStylesRef('icon'),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
        marginRight: theme.spacing.sm,
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({variant: 'light', color: theme.primaryColor}).background,
            color: theme.fn.variant({variant: 'light', color: theme.primaryColor}).color,
            [`& .${getStylesRef('icon')}`]: {
                color: theme.fn.variant({variant: 'light', color: theme.primaryColor}).color,
            },
        },
    },
}));

interface Menu {
    link?: string,
    label: string,
    icon: any,
    sub?: Menu[]
}

interface Menus {
    admin: Menu[],
    manager: Menu[],
    content_manager: Menu[],
}

const admin: Menu[] = [
    {icon: IconHome, label: 'Главная', link: "/"},
    {icon: IconBriefcase, label: 'Старые заказы', link: "/orders-migrated"},
    {icon: IconMoneybag, label: 'Финансы', link: "/finances"},
    {icon: IconBrandGoogleAnalytics, label: 'Аналитика', link: "/analytics"},
    {
        icon: IconHexagonLetterA, label: 'Админ', sub: [
            {icon: IconUserCircle, label: 'Пользователи', link: "/users"},
            {icon: IconUser, label: 'Исполнители', link: "/executors"},
            {icon: IconDotsCircleHorizontal, label: 'Офисы', link: "/offices"}
        ]
    },
    {
        icon: IconLayoutGrid, label: 'Система', sub: [
            {icon: IconLayoutGrid, label: 'Main-SMS', link: "/system/main-sms"}
        ]
    },
    {
        icon: IconBuildingSkyscraper, label: 'Менеджмент', sub: [
            {icon: IconLayersIntersect, label: 'Заказы', link: "/orders"},
            {icon: IconGitBranchDeleted, label: 'Отказные Заказы', link: "/refused-orders"},
            {icon: IconFriends, label: 'Клиенты', link: "/clients"},
            {icon: IconDeviceLaptop, label: 'Устройства', link: "/devices"}
        ]
    },
    {
        icon: IconDots, label: 'Прочее', sub: [
            {icon: IconZoomQuestion, label: 'Откуда Узнали', link: "/where-known"},
            {icon: IconFloatRight, label: 'Внешний вид', link: "/device-appearance"}
        ]
    },
    {
        icon: IconBrowser, label: 'CMS', sub: [
            {icon: IconCategory, label: 'Услуги', link: "/cms/services"},
            {icon: IconZoomMoney, label: 'Цены на услуги', link: "/cms/prices"}
        ]
    }

];

const manager: Menu[] = [
    {icon: IconHome, label: 'Главная', link: "/"},
    {icon: IconBriefcase, label: 'Старые заказы', link: "/orders-migrated"},
    {
        icon: IconBuildingSkyscraper, label: 'Менеджмент', sub: [
            {icon: IconLayersIntersect, label: 'Заказы', link: "/orders"},
            {icon: IconFriends, label: 'Клиенты', link: "/clients"},
            {icon: IconDeviceLaptop, label: 'Устройства', link: "/devices"}
        ]
    },
    {
        icon: IconDots, label: 'Прочее', sub: [
            {icon: IconZoomQuestion, label: 'Откуда Узнали', link: "/where-known"},
            {icon: IconFloatRight, label: 'Внешний вид', link: "/device-appearance"}
        ]
    },
];

const content: Menu[] = [
    {icon: IconCategory, label: 'Услуги', link: "/cms/services"}
]


const menus: Menus = {
    'admin': admin,
    'manager': manager,
    'content_manager': content,
}

const Links = () => {
    const role = useAppSelector(state => state.authorization.user.role)
    const {classes, cx} = useStyles();
    let location = useLocation();
    const dispatch = useAppDispatch();
    const isSmallScreen = useMediaQuery('(max-width: 500px)');
    function links(data: Menu[]) {
        return data?.map(item => {
            if (item.sub) {
                return <NavLink key={item.label}
                                label={item.label}
                                className={classes.link}
                                icon={<item.icon className={classes.linkIcon}/>}
                >
                    {links(item.sub)}
                </NavLink>
            }
            return (
                <Link to={item.link || ''} className={classes.routerLink}
                      key={item.label}
                      onClick={() => {
                          isSmallScreen &&  dispatch(toggleSideBar())
                      }}
                >
                    <NavLink label={item.label}
                             icon={<item.icon className={classes.linkIcon}/>}
                             className={cx(classes.link, {[classes.linkActive]: item.link === location.pathname})}
                    />
                </Link>)
        })
    }

    return <>{links(role ? menus[role] : [])}</>
}

export default Links
