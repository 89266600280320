import {showNotification} from "@mantine/notifications";
import notificationSound2 from './notification_sound_2.mp3'

const audio = new Audio(notificationSound2);
export function feedbackNotification() {

    //play notification
    audio.play().then().catch(() => {
        //error play
    });

    //show message
    showNotification({
        title: "Новая заявка с сайта",
        message: "",
        autoClose: 2000,
        styles: (theme) => ({
            root: {
                backgroundColor: theme.colors.orange[6],
                borderColor: theme.colors.orange[6],

                '&::before': {backgroundColor: theme.white},
            },

            title: {color: theme.white},
            description: {color: theme.white},
            closeButton: {
                color: theme.white,
                '&:hover': {backgroundColor: theme.colors.orange[7]},
            },
        }),
    })
}