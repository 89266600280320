import React from 'react';
import {IOrder} from "../../api/types";
import "./print.css"
import Guarantee from "./Guarantee";
import Reception from "./Reception";

const DocumentToPrint = (props: {
    docRef: React.RefObject<any>,
    type?: string,
    orderRecord?: IOrder & { guaranteeText?: string },
    addStamp: boolean
}) => {

    if (!props.type || !props.orderRecord) {
        return <div ref={props.docRef} style={{display: 'none'}}></div>
    }

    return (
        <div style={{display: 'none'}}>
            <div ref={props.docRef}>
                <style type="text/css">
                    {`@media print{@page {
                    size: A4 portrait;
                    margin: 20mm;
                    }`}
                </style>
                {props.type === 'guarantee' ? (
                    <Guarantee order={props.orderRecord} addStamp={props.addStamp}/>
                ) : (
                    <Reception order={props.orderRecord} addStamp={props.addStamp}/>
                )}
            </div>
        </div>
    )
}

export default DocumentToPrint
