import {useGetCmsPricesQuery} from "../../../api/cms/cms-prices";
import {Button, Checkbox, createStyles, Divider, Flex, Pagination, Paper, Select, Table} from "@mantine/core";
import React, {useState} from "react";
import {usePagination} from "@mantine/hooks";
import PageContainer from "../../../components/PageContainer";
import {useGetCmsServicePositionsQuery} from "../../../api/cms/cms-service-positions";
import EditableRow from "./EditableRow";
import CreateTemplate from "./CreateTemplate";

const useStyles = createStyles((theme) => ({
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 5px',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    },
    tableHead: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        borderBottom: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]
    },
    table: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
    clientNameText: {
        '&:hover': {
            textDecoration: "underline"
        }
    }
}))
const Prices = () => {
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const [openedCreateTemplate, setOpenedCreateTemplate] = useState(false);
    const {data = {content: [], totalPages: 0}, isLoading} = useGetCmsPricesQuery({
        page: 1,
        pageSize: 50,
        filter: {
            servicePosition: selectedService || ""
        }
    });
    const {data: servicePositions = {content: [], totalPages: 0}} = useGetCmsServicePositionsQuery({
        page: 1,
        pageSize: 999,
    })
    const [page, setPage] = useState(1)
    const {classes} = useStyles();
    const pagination = usePagination({total: data.totalPages, initialPage: 1, page: page, onChange: setPage})
    return (
        <PageContainer>
            <div>
                <Select
                    my={20}
                    label="Услуга"
                    searchable
                    creatable
                    onChange={(value) => setSelectedService(value)}
                    placeholder="Выбирите услугу"
                    data={servicePositions.content.map(item => {
                        return {label: item.title, value: item._id || ""}
                    })}
                />
            </div>
            <Paper>
                {data.content.length > 0 && (
                    <Flex justify={'end'} p={5}>
                        <Button variant={"outline"} size={"xs"} onClick={() => setOpenedCreateTemplate(true)}>
                            Создать шаблон
                        </Button>
                    </Flex>
                )}
                <Table highlightOnHover cellPadding={10}>
                    <thead className={classes.tableHead}>
                    <tr>
                        <td>
                            Устройство
                        </td>
                        <td width={250}>
                            Наименование
                        </td>
                        <td>
                            Цена
                        </td>
                        <td width={100}>
                            Cкидка
                        </td>
                        <td>
                            Плав. цена
                        </td>
                        <td>
                            Время исполнения
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.content.map(price => {
                        return (
                            <EditableRow price={price}/>
                        )
                    })}
                    </tbody>
                </Table>
                <div>
                    <div className={classes.paginationContainer}>
                        <Pagination
                            total={(data || {totalPages: 0}).totalPages}
                            onChange={(value) => {
                                pagination.setPage(value)
                            }}
                        />
                    </div>
                </div>
            </Paper>
            <CreateTemplate
                opened={openedCreateTemplate}
                onClose={() => setOpenedCreateTemplate(false)}
                prices={data.content}
            />
        </PageContainer>
    )
}

export default Prices;