import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
    ActionIcon,
    Button,
    Checkbox, createStyles,
    NumberInput, Paper,
    rem,
    ScrollArea,
    Table,
    Textarea,
    TextInput,
    Text, useMantineTheme, Flex, Divider
} from "@mantine/core";
import {IconCheck, IconEdit, IconGripVertical, IconPlus, IconTrashX} from "@tabler/icons-react";
import {IPriceItem} from "../types";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";
import PriceTemplates from "./PriceTemplates";
import {useFormContext} from "./form-context";
import {useDeletePriceMutation} from "../../../api/cms/cms-prices";

const useStylesPrices = createStyles((theme) => ({
    item: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    dragHandle: {
        ...theme.fn.focusStyles(),
        width: rem(40),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    },
    tableContainer: {
        padding: '5px 0'
        // overflow: 'hidden'
    },
    description: {
        // maxWidth: '500px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    createNewContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0'
    },
    itemActionsContainer: {
        display: 'flex'
    }
}));

const PricesTable = () => {
    const {classes} = useStylesPrices();
    const [ConfirmModal, confirmDelete] = useConfirmationModal({title: 'Удалить?'});
    const [inEditIndex, setInEditIndex] = useState<number | false>(false);
    const [isOpenedPriceTemplates, setIsOpenedPriceTemplates] = useState(false);
    const colorScheme = useMantineTheme().colorScheme;
    const form = useFormContext();
    const [deletePrice] = useDeletePriceMutation();

    async function deletePriceItem(index: number) {
        const res = await confirmDelete();
        if (res) {
            const prices = form.getInputProps("prices").value
            const priceItem = prices[index];
            try {
                if (priceItem._id) {
                    await deletePrice({id: priceItem._id}).unwrap();
                }
                form.removeListItem('prices', index);
            } catch (e) {
                console.log(e)
            }

        }
    }

    function openPriceTemplates() {
        setIsOpenedPriceTemplates(true)
    }

    function onSelectTemplate(template: IPriceItem[]) {
        form.setFieldValue('prices', template)
        setIsOpenedPriceTemplates(false)
    }

    const items = form.values.prices.map((item, index: number) => (
        <Draggable key={index} index={index} draggableId={String(index)}>
            {(provided) => (
                <tr className={classes.item} ref={provided.innerRef} {...provided.draggableProps}>
                    <td style={{width: rem(40)}}>
                        <div className={classes.dragHandle} {...provided.dragHandleProps}>
                            <IconGripVertical size="1.05rem" stroke={1.5}/>
                        </div>
                    </td>
                    {inEditIndex === index ? (
                        <>
                            <td>
                                <TextInput
                                    autoFocus={true}
                                    my={"md"}
                                    withAsterisk
                                    required
                                    placeholder={'Замена экрана'}
                                    {...form.getInputProps(`prices.${index}.title`)}
                                />
                                <Textarea
                                    my={"md"}
                                    withAsterisk
                                    required
                                    placeholder={'Описание'}
                                    {...form.getInputProps(`prices.${index}.description`)}
                                />
                            </td>
                            <td width={400}>
                                <>
                                    <Flex>
                                        <NumberInput
                                            disabled={Boolean(form.getInputProps(`prices.${index}.isFloat`).value)}
                                            type={'number'}
                                            withAsterisk
                                            required
                                            placeholder={'Цена'}
                                            {...form.getInputProps(`prices.${index}.defaultPrice`)}
                                        />
                                        <Checkbox
                                            disabled={Boolean(form.getInputProps(`prices.${index}.isFloat`).value)}
                                            mx={10}
                                            mt={8}
                                            label="Скидка"
                                            {...form.getInputProps(`prices.${index}.isPromoted`, {type: 'checkbox'})}
                                        />
                                        <NumberInput
                                            disabled={(Boolean(form.getInputProps(`prices.${index}.isFloat`).value) || !Boolean(form.getInputProps(`prices.${index}.isPromoted`).value))}
                                            type={'number'}
                                            withAsterisk
                                            required
                                            placeholder={'Цена со скидкой'}
                                            {...form.getInputProps(`prices.${index}.promotedPrice`)}
                                        />
                                    </Flex>
                                    <Divider my={10}/>
                                    <Flex align={"center"}>
                                        <Checkbox
                                            mx={10}
                                            mt={15}
                                            {...form.getInputProps(`prices.${index}.isFloat`, {type: 'checkbox'})}
                                        />
                                        <TextInput
                                            w={'100%'}
                                            disabled={!Boolean(form.getInputProps(`prices.${index}.isFloat`).value)}
                                            label={'Плавающая цена'}
                                            {...form.getInputProps(`prices.${index}.floatPrice`)}
                                        />
                                    </Flex>
                                </>
                            </td>
                            <td width={200}>
                                <TextInput
                                    my={"md"}
                                    withAsterisk
                                    required
                                    placeholder={'Время ремонта'}
                                    {...form.getInputProps(`prices.${index}.time`)}
                                />
                            </td>
                            <td style={{width: rem(40)}}>
                                <div className={classes.itemActionsContainer}>
                                    <ActionIcon onClick={() => setInEditIndex(false)}>
                                        <IconCheck/>
                                    </ActionIcon>
                                    <ActionIcon onClick={() => deletePriceItem(index)}>
                                        <IconTrashX/>
                                    </ActionIcon>
                                </div>
                            </td>
                        </>
                    ) : (
                        <>
                            <td style={{overflow: 'hidden', maxWidth: '500px'}}>
                                <p>{item.title}</p>
                                <p className={classes.description}>{item.description}</p>
                            </td>
                            <td style={{width: 300}}>
                                <div>
                                    {item.isFloat ? (
                                        <Text>{item.floatPrice}</Text>
                                    ) : (
                                        <>
                                            <Text strikethrough={Boolean(item.promotedPrice)}>{item.defaultPrice}</Text>
                                            <Text>{item.promotedPrice}</Text>
                                        </>
                                    )}
                                </div>
                            </td>
                            <td>
                                {item.time}
                            </td>
                            <td style={{width: rem(40)}}>
                                <div className={classes.itemActionsContainer}>
                                    <ActionIcon onClick={() => setInEditIndex(index)}>
                                        <IconEdit/>
                                    </ActionIcon>
                                    <ActionIcon onClick={() => deletePriceItem(index)}>
                                        <IconTrashX/>
                                    </ActionIcon>
                                </div>
                            </td>
                        </>
                    )}
                </tr>
            )}
        </Draggable>
    ));
    return (
        <Paper py={5} mt={10}>
            <ScrollArea>
                <DragDropContext
                    onDragEnd={({destination, source}) =>
                        form.reorderListItem('prices', {from: source.index, to: destination?.index || 0})
                    }
                >
                    <Table sx={{minWidth: rem(620), '& tbody tr td': {borderBottom: 0}}}>
                        <thead>
                        <tr>
                            <th style={{width: rem(40)}}/>
                            <th>Название</th>
                            <th style={{width: rem(140)}}>Цена</th>
                            <th style={{width: rem(140)}}>Время</th>
                            <th style={{width: rem(40)}}/>
                        </tr>
                        </thead>
                        <Droppable droppableId="dnd-list" direction="vertical">
                            {(provided) => (
                                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                {items.length ? (
                                    items
                                ) : (
                                    <tr>

                                        <td style={{textAlign: 'center'}} colSpan={5}>
                                            <Paper bg={colorScheme === 'light' ? 'gray.1' : 'gray.8'} p={10}>
                                                <Text pb={5}>Ни одной цены еще не было добавленно</Text>
                                                <Button size={"xs"} variant={"outline"} onClick={openPriceTemplates}>Создать
                                                    из шаблона</Button>
                                                <Text span color={"dimmed"}> или </Text>
                                                <Button size={"xs"} variant={"outline"}
                                                        onClick={() => {
                                                            form.insertListItem('prices', {
                                                                title: '',
                                                                price: '',
                                                                description: ''
                                                            })
                                                            setInEditIndex(0)
                                                        }}
                                                >Создать в ручную</Button>
                                            </Paper>
                                        </td>

                                    </tr>
                                )}
                                {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
                {Boolean(items.length) && (
                    <div className={classes.createNewContainer}>
                        <Button rightIcon={<IconPlus/>}
                                onClick={() => {
                                    form.insertListItem('prices', {title: '', price: '', description: ''})
                                    setInEditIndex(items.length)
                                }}
                        >
                            Добавить
                        </Button>
                    </div>
                )}
            </ScrollArea>
            <ConfirmModal/>
            <PriceTemplates
                opened={isOpenedPriceTemplates}
                onClose={() => setIsOpenedPriceTemplates(false)}
                onSelectTemplate={onSelectTemplate}
            />
        </Paper>
    );
}
export default PricesTable
