import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import {showNotification} from '@mantine/notifications';

const localStorageUserInfo: LocalStorageUserInfo = JSON.parse(localStorage.getItem('userInfo') || "{}")
const userToken = localStorage.getItem('userToken') || null

interface AuthResponse {
    token: string,
    userId: string,
    userName: string,
    role: 'admin' | 'manager' | 'content_manager' | null,
    offices: { _id: string, name: string }[],
}

interface LocalStorageUserInfo {
    userId: string,
    userName: string,
    role: 'admin' | 'manager' | 'content_manager' | null,
    offices: AuthResponse['offices'],
    selectedOffice: string
}

export interface AuthState {
    user: {
        userId: string | null,
        userName: string | null,
        offices: AuthResponse['offices']
        role: 'admin' | 'manager' | 'content_manager' | null,
    },
    selectedOffice: string,
    userToken: null | string,
    isAuth: boolean,
    loading: boolean,
    error: null | string
}

export interface SignInPayload {
    username: string,
    password: string
}

const initialState: AuthState = {
    user: {
        userId: localStorageUserInfo.userId || null,
        userName: localStorageUserInfo.userName || null,
        role: localStorageUserInfo.role || null,
        offices: localStorageUserInfo.offices || [],
    },
    selectedOffice: localStorageUserInfo.selectedOffice,
    userToken,
    isAuth: false,
    loading: false,
    error: null
};

export const signInAsync = createAsyncThunk<AuthResponse, SignInPayload, { rejectValue: string }>('auth/signIn',
    async (payload, thunkApi) => {
        try {
            const result = await axios.post<AuthResponse>('/api/auth/login', payload)
            localStorage.setItem('userToken', result.data.token)
            localStorage.setItem('userInfo', JSON.stringify({
                userName: result.data.userName,
                role: result.data.role,
                offices: result.data.offices,
                userId: result.data.userId,
                selectedOffice:  result.data.offices.length ? result.data.offices?.[0]._id || null : null
            }))
            return result.data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) {
                    return thunkApi.rejectWithValue(e.response.data.message)
                }
            }
            return thunkApi.rejectWithValue(e as string)
        }
    }
)

export const signOutAsync = createAsyncThunk('auth/signOut',
    async (payload, thunkApi) => {
        try {
            // await axios.post('/api/auth/logout')
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) {
                    return thunkApi.rejectWithValue(e.response.data.message as string)
                }
            }
            return thunkApi.rejectWithValue(e as string)
        }
    }
)

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        clearAuthorization: (state) => {
            localStorage.clear();
            state.userToken = null;
        },
        changeOffice: (state, action) => {
            state.selectedOffice = action.payload
            localStorage.setItem('userInfo', JSON.stringify({...state.user, selectedOffice: action.payload}))
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(signInAsync.fulfilled, (state, {payload}) => {
                state.userToken = payload.token;
                state.user = {
                    userId: payload.userId,
                    userName: payload.userName,
                    offices: payload.offices,
                    role: payload.role
                };
                state.selectedOffice = JSON.parse(localStorage.getItem('userInfo') || "{}").selectedOffice;
                state.error = null;
                state.loading = false;
                state.isAuth = true;
            })
            .addCase(signInAsync.rejected, (state, {payload}) => {
                state.loading = false
                state.error = payload || null;
                state.isAuth = false;
                showNotification({
                    color: 'red',
                    title: 'Ошибка авторизации',
                    message: payload,
                })
            })
        builder
            .addCase(signOutAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(signOutAsync.fulfilled, (state) => {
                state.loading = false;
                state.user = initialState.user
                state.isAuth = false;
                state.userToken = null;
            })
            .addCase(signOutAsync.rejected, (state) => {
                state.loading = false
                showNotification({
                    color: 'red',
                    title: 'Ошибка',
                    message: 'Server error',
                })
            })
    },
});
export default authorizationSlice.reducer;

export const {clearAuthorization, changeOffice} = authorizationSlice.actions;
