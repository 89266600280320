import {api} from "./api";
import {ICrudResponse, IFinances, IQueryParams} from "./types";

export interface ICashBoxTransaction {
    amount: number,
    type: string,
    description: string,
    paymentType: string,
    manager: string,
    office: string
}

export const financesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFinances: build.query<{ content: IFinances[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/finances/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: JSON.stringify(params.filter),
                    search: params.search,
                    sort: JSON.stringify(params.sort)
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Finances', tag}],
        }),
        getCashCardMoney: build.query<{ card: number, cash: number, transfer: number, sum: number }, { office: string }>({
            query: (params) => ({
                url: `/api/finances/getCashCardMoney`,
                params: {filter: JSON.stringify({office: params.office})}
            }),
            providesTags: (result, error, tag) => [{type: 'Finances', tag}],
        }),
        cashBoxTransaction: build.mutation<ICrudResponse, ICashBoxTransaction>({
            query: (payload) => ({
                url: "/api/finances/cashBoxTransaction",
                method: 'POST',
                body: payload
            }),
            invalidatesTags: [{type: 'Finances'}]
        })
    })
})

export const {
    useGetFinancesQuery,
    useGetCashCardMoneyQuery,
    useCashBoxTransactionMutation
} = financesApi
