import {api} from "../api";
import {IPriceItem, IServiceQueryParams} from "../../pages/cms/types";

export interface IPricesTemplateQuery {
    _id: string,
    title: string,
    prices: IPriceItem[]
}

export interface IPricesTemplate {
    title: string,
    prices: IPriceItem[]
}

export const cmsPricesTemplateApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCmsPricesTemplate: build.query<{ content: IPricesTemplateQuery[], totalPages: number }, IServiceQueryParams>({
            query: (params) => ({
                url: `/api/cms/pricesTemplate/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    sort: params.sort,
                    search: params.search,
                }
            }),
            providesTags: [{type: 'PricesTemplate'}],
        }),
        createCmsPricesTemplate: build.mutation<IPricesTemplate, IPricesTemplate>({
            query: (payload) => ({
                url: '/api/cms/pricesTemplate/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'PricesTemplate'}]
        }),
        updateCmsPricesTemplate: build.mutation<IPricesTemplate, IPricesTemplate>({
            query: (payload) => ({
                url: '/api/cms/pricesTemplate/update',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'PricesTemplate'}]
        }),
        deleteCmsPricesTemplate: build.mutation<{_id: string}, any>({
            query: (payload) => ({
                url: '/api/cms/pricesTemplate/delete',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'PricesTemplate'}]
        }),
        readCmsPricesTemplate: build.query<IPricesTemplate, { _id?: string }>({
            query: (params) => ({
                url: `/api/cms/pricesTemplate/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: [{type: 'PricesTemplate'}],
        }),
    })
})

export const {
    useGetCmsPricesTemplateQuery,
    useCreateCmsPricesTemplateMutation,
    useDeleteCmsPricesTemplateMutation,
    useReadCmsPricesTemplateQuery,
    useUpdateCmsPricesTemplateMutation,
} = cmsPricesTemplateApi
