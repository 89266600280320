import {api} from "./api";
import {ICrudResponse, IDeviceModel, IDeviceModelCreate, IQueryParams} from "./types";

export const deviceModelApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeviceModels: build.query<{ content: IDeviceModel[] }, IQueryParams>({
            query: (params) => ({
                url: `/api/deviceModel/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceModel', tag}],
        }),
        createDeviceModel: build.mutation<ICrudResponse, IDeviceModelCreate>({
            query: (payload) => ({
                url: '/api/deviceModel/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceModel'}]
        }),
        updateDeviceModel: build.mutation<ICrudResponse, IDeviceModel>({
            query: (payload) => ({
                url: '/api/deviceModel/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceModel'}]
        }),
        readDeviceModel: build.query<IDeviceModel, { _id?: string }>({
            query: (params) => ({
                url: `/api/deviceModel/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceModel', tag}],
        }),
        removeDeviceModel: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/deviceModel/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceModel'}]
        })
    })
})

export const {
    useCreateDeviceModelMutation,
    useUpdateDeviceModelMutation,
    useRemoveDeviceModelMutation,
    useReadDeviceModelQuery,
    useGetDeviceModelsQuery,
} = deviceModelApi