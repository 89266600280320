import React, {useState} from "react";
import {useGetCmsGalleryQuery} from "../../api/cms/cms-gallery";
import {ActionIcon, CheckIcon, createStyles, Flex, Grid, Image, Pagination, Paper, Tooltip} from "@mantine/core";
import {IconCircleCheck, IconTrash, IconTrashFilled, IconX} from "@tabler/icons-react";
import {useConfirmationModal} from "../../hooks/useConfirmationModal";

const useStyles = createStyles((theme) => ({
    paper: {
        height: 200,
        width: 200,
        borderRadius: 26,
        '&:hover': {
            border: "1px solid",

        },
        position: "relative",
    },
    gridCol: {
        display: 'flex',
        justifyContent: 'center',

    },
    imageMenu: {
        position: "absolute",
        borderTop: '1px solid',
        display: 'flex',
        justifyContent: "space-evenly",
        width: "100%",
        height: 25,
        bottom: 0,
    }

}))
const Images = (props: { onSelect: (imageSrc: string) => void }) => {
    const {classes} = useStyles();
    const [ConfirmModal, confirmDelete] = useConfirmationModal({})
    const [selectedImage, setSelectedImage] = useState<{ _id: string, url: string } | null>(null)
    const [page, setPage] = useState(1);
    const {data = {content: [], totalPages: 0}} = useGetCmsGalleryQuery({
        page,
        pageSize: 12,
        sort: {column: "_id", direction: "descend"}
    });


    function onSelectImage() {
        if (selectedImage && selectedImage.url) {
            props.onSelect(selectedImage?.url)
        }
    }

    async function onDeleteImage() {
        try {
            await confirmDelete();
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
            <Grid gutter={"xl"}>
                {data.content.map(image => {
                    const isSelected = Boolean(selectedImage?._id === image._id);
                    return (
                        <Grid.Col md={6} lg={3} xl={2} className={classes.gridCol}>
                            <Paper key={image._id}
                                   className={classes.paper}
                                   shadow={isSelected ? "md" : "xs"}
                                   p={5}
                                   style={{
                                       display: 'flex',
                                       flexDirection: "column",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       border: isSelected ? '1px solid' : 0
                                   }}
                                   onClick={() => setSelectedImage(image)}
                            >
                                <Image
                                    width={'100'}
                                    src={'https://apple4you.ru' + image.url}
                                />
                                {isSelected && (
                                    <div className={classes.imageMenu}>
                                        <Tooltip label={'Выбрать'} onClick={onSelectImage}>
                                            <ActionIcon size={'sm'}>
                                                <IconCircleCheck color={'green'} size={22}/>
                                            </ActionIcon>
                                        </Tooltip>
                                        <Tooltip label={'Удалить'} onClick={onDeleteImage}>
                                            <ActionIcon size={'sm'}>
                                                <IconTrash color={'red'} size={22}/>
                                            </ActionIcon>
                                        </Tooltip>
                                    </div>
                                )}
                            </Paper>

                        </Grid.Col>
                    )
                })}
            </Grid>
            <Flex justify={'end'}>
                <Pagination total={data.totalPages} value={page} onChange={setPage} my={40}/>
            </Flex>
            <ConfirmModal/>
        </>
    )
}
export default Images