import {api} from "./api";

export const analyticsApi = api.injectEndpoints({
    endpoints: (build) => ({
        averagePriceIncome: build.query<{ check: number, income: number }, {filter: {office: string, readyDate: {"$lt": string, "$gt": string}}}>({
            query: (params) => ({
                url: `/api/analytics/averagePriceIncome`,
                params: {
                    filter: JSON.stringify(params.filter)
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Analytics', tag}],
        }),
        getIncomeByMonths: build.query<{ availableYears: number[], data: {month: string, value: number}[] }, {filter: {office: string, year: number}}>({
            query: (params) => ({
                url: `/api/analytics/getIncomeByMonths`,
                params: {
                    filter: JSON.stringify(params.filter)
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Analytics', tag}],
        }),
        whereKnownClients: build.query<{type: string, value: number}[], {filter: {office: string}}>({
            query: (params) => ({
                url: `/api/analytics/whereKnownClients`,
                params: {
                    filter: JSON.stringify(params.filter)
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Analytics', tag}],
        }),
    })
})

export const {
    useAveragePriceIncomeQuery,
    useGetIncomeByMonthsQuery,
    useWhereKnownClientsQuery
} = analyticsApi