import React from "react";
import {IconChevronDown, IconChevronUp, IconSelector} from "@tabler/icons-react";
import {Center, createStyles, Group, rem, Text, UnstyledButton} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    control: {
        width: '100%',
        padding: `${theme.spacing.xs} 5px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: rem(21),
        height: rem(21),
        borderRadius: rem(21),
    },
}))

interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;

    onSort(): void;

    width: number | string;
    sortable: boolean;
}

function Th({children, reversed, sorted, onSort, width, sortable}: ThProps) {
    const {classes} = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th style={{width}}>
            {sortable ? (
                <UnstyledButton onClick={onSort} className={classes.control}>
                    <Group position="apart">
                        <Text fw={500} fz="sm">
                            {children}
                        </Text>
                        <Center className={classes.icon}>
                            <Icon size="0.9rem" stroke={1.5}/>
                        </Center>
                    </Group>
                </UnstyledButton>
            ) : (
                children
            )}

        </th>
    );
}

export default Th
