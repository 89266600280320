import React, {useState} from "react";
import {ActionIcon, CopyButton, createStyles, Text, Tooltip} from "@mantine/core";
import {IconCheck, IconCopy} from "@tabler/icons-react";
import DefaultTable from "../../components/DefaultTable";
import {useGetOrdersMigratedQuery} from "../../api/ordersMigrated";
import {useDebouncedState} from "@mantine/hooks";
import PageContainer from "../../components/PageContainer";

const useStyles = createStyles(() => ({
    clientNameText: {
        '&:hover': {
            textDecoration: "underline"
        }
    }
}))
const OldOrders = () => {
    const {classes} = useStyles();
    const [page, setPage] = useState(1)
    const [search, setSearch] = useDebouncedState('', 200)
    const {data = {content: [], totalPages: 0}, isFetching} = useGetOrdersMigratedQuery({page, pageSize: 10, search})
    return (
        <PageContainer>
            <DefaultTable data={data}
                          columns={[
                              {name: 'orderNo', label: 'Номер заказа'},
                              {
                                  name: 'phone', label: 'Клиент', render: (value, record) => {
                                      return (
                                          <div onClick={(e) => e.stopPropagation()}>
                                              <Text
                                                  className={classes.clientNameText}
                                                  weight={500} style={{cursor: "pointer"}}
                                                  onClick={(e) => {
                                                      e.stopPropagation();
                                                      // openOrdersByClient(rowItem)
                                                  }}
                                              >{record?.clientName},</Text>
                                              <a href={`tel:${record?.phone}`}>+{record?.phone}</a>
                                          </div>
                                      )
                                  }
                              },
                              {
                                  name: 'serial', label: 'Устройство', render: (data, record) => {
                                      return (
                                          <>
                                              {record.brandName} {record.deviceModel}<br/>
                                              <div style={{borderBottom: '1px solid rgba(0,0,0,.06)'}}></div>
                                              <div style={{display: 'flex', alignItems: 'center'}}>
                                                  <div style={{marginRight: 3}}>
                                                      {record.serial}
                                                  </div>
                                                  <CopyButton value={record.serial}>
                                                      {({copied, copy}) => (
                                                          <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow
                                                                   position="right">
                                                              <ActionIcon color={copied ? 'teal' : 'blue'}
                                                                          onClick={e => {
                                                                              e.stopPropagation();
                                                                              copy()
                                                                          }}
                                                                          style={{color: 'cyan.6'}}>
                                                                  {copied ? <IconCheck size="1rem"/> :
                                                                      <IconCopy size="1rem"/>}
                                                              </ActionIcon>
                                                          </Tooltip>
                                                      )}
                                                  </CopyButton>
                                              </div>
                                          </>
                                      )
                                  }
                              },
                              {name: 'description', label: 'Неисправность'},
                              {name: 'date_end', label: 'Дата выдачи'},
                              {
                                  name: 'cost', label: 'Цена ремонта', render: (value) => {
                                      return new Intl.NumberFormat('ru-RU', {
                                          style: 'currency',
                                          currency: 'RUB'
                                      }).format(value)
                                  }
                              },
                          ]}
                          onSearch={setSearch}
                          searchPlaceholder={'Поиск по всем полям'}
                          enableSearch={true}
                          onChangePage={setPage}
                          isFetching={isFetching}
                          page={page}/>
        </PageContainer>

    )
}
export default OldOrders;