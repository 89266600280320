import {Button, createStyles, Divider, Pagination, Paper, rem, ScrollArea, Table, TextInput} from "@mantine/core";
import {usePagination} from "@mantine/hooks";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Th from "./ordersTable/Th";
import React from "react";
import {IconGripVertical, IconSearch} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 5px',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#fff'
    },
    tableHead: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    },
    table: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
    clientNameText: {
        '&:hover': {
            textDecoration: "underline"
        }
    },
    dragHandle: {
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.colors.gray[6]
    }
}))

interface IColumn {
    name: string,
    label: string,
    type?: string,
    width?: string,
    render?: (string: any, {}: any) => any,
    sortable?: boolean,
}

interface IProps {
    isFetching: boolean,
    columns: IColumn[],
    data: { content: any[], totalPages: number },
    onRowClick?: (item: any) => void,
    onChangePage: (page: number) => void,
    page: number,
    sort?: { column: string, direction: string },
    setSort?: (v: string) => void,
    onSearch?: (v: string) => void,
    enableSearch?: boolean,
    searchPlaceholder?: string,
    creatable?: boolean,
    onClickCreate?: () => void,
    onDragEnd: (destination: number, source: number) => void,
}

const DefaultTableDraggable = (props: IProps) => {
    const {
        isFetching,
        columns,
        data,
        onRowClick = () => {
        },
        onDragEnd,
        page,
        onChangePage,
        onSearch,
        sort,
        setSort,
        enableSearch,
        searchPlaceholder,
        creatable,
        onClickCreate
    } = props;
    const {classes} = useStyles();
    const pagination = usePagination({total: data.totalPages, initialPage: 1, page: page, onChange: onChangePage})

    const items = (data || {content: []}).content.map((item: any, i: number) => (
        <Draggable key={item.position} index={i} draggableId={String(item.position)}>
            {(provided) => (
                <tr key={i} onClick={() => onRowClick(item)}
                    ref={provided.innerRef} {...provided.draggableProps}>
                    <td>
                        <div className={classes.dragHandle} {...provided.dragHandleProps}>
                            <IconGripVertical style={{width: rem(18), height: rem(18)}} stroke={1.5}/>
                        </div>
                    </td>
                    {columns.map((column: IColumn) => {
                        return (
                            <td key={column.name}>
                                {
                                    column.render ?
                                        column.render(item[column.name], item) :
                                        item[column.name]
                                }
                            </td>
                        )
                    })}
                </tr>
            )}
        </Draggable>
    ));

    return (
        <>
            <Paper sx={{display: 'flex', alignItems: 'center'}} mb="md">
                {(enableSearch && onSearch) && (
                    <TextInput
                        style={{width: '100%'}}
                        placeholder={searchPlaceholder}
                        icon={<IconSearch size="0.9rem" stroke={1.5}/>}
                        onChange={(e) => onSearch(e.target.value)}
                    />
                )}
                {(creatable && onClickCreate) && (
                    <Button ml={10} onClick={onClickCreate}>Создать</Button>
                )}
            </Paper>
            <Paper mt={"sm"}>
            <ScrollArea>
                <DragDropContext
                    onDragEnd={({destination, source}) => {
                        if (destination?.index === null || destination?.index === undefined) return;
                        onDragEnd(destination.index, source.index)
                    }}
                >
                    <Table>
                        <thead>
                        <tr>
                            <th></th>
                            {columns.map(column => (
                                <Th
                                    key={column.name}
                                    sortable={Boolean(column.sortable)}
                                    width={column.width || 'auto'}
                                    onSort={() => setSort && setSort(column.name)}
                                    sorted={Boolean(sort?.column === column.name && sort.direction)}
                                    reversed={Boolean(sort?.column === column.name && sort.direction === 'descend')}
                                >
                                    {column.label}
                                </Th>
                            ))}
                        </tr>
                        </thead>
                        <Droppable droppableId="dnd-list" direction="vertical">
                            {(provided) => (
                                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                {items}
                                {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </Table>
                    <Divider/>
                    <div className={classes.paginationContainer}>
                        <Pagination
                            total={(data || {totalPages: 0}).totalPages}
                            onChange={(value) => {
                                pagination.setPage(value)
                            }}
                        />
                    </div>
                </DragDropContext>
            </ScrollArea>
            </Paper>
        </>

    )
}
export default DefaultTableDraggable;