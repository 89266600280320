import {api} from "./api";
import {ICrudResponse, IDeviceBrand, IDeviceBrandCreate, IQueryParams} from "./types";

export const deviceBrandApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeviceBrands: build.query<{ content: IDeviceBrand[] }, IQueryParams>({
            query: (params) => ({
                url: `/api/deviceBrand/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter,
                    sort: JSON.stringify(params.sort)
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceBrand', tag}],
        }),
        createDeviceBrand: build.mutation<ICrudResponse, IDeviceBrandCreate>({
            query: (payload) => ({
                url: '/api/deviceBrand/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceBrand'}]
        }),
        updateDeviceBrand: build.mutation<ICrudResponse, IDeviceBrand>({
            query: (payload) => ({
                url: '/api/deviceBrand/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceBrand'}]
        }),
        readDeviceBrand: build.query<IDeviceBrand, { _id?: string }>({
            query: (params) => ({
                url: `/api/deviceBrand/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceBrand', tag}],
        }),
        removeDeviceBrand: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/deviceBrand/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceBrand'}]
        })
    })
})

export const {
    useCreateDeviceBrandMutation,
    useUpdateDeviceBrandMutation,
    useRemoveDeviceBrandMutation,
    useReadDeviceBrandQuery,
    useGetDeviceBrandsQuery,
} = deviceBrandApi