import {api} from "../api";

import {IPriceItem, IServicePosition} from "../../pages/cms/types";

interface IPriceQueryParams {
    sort?: { column: string, direction: string },
    search?: string,
    status?: string[],
    page: number,
    pageSize: number,
    filter?: { servicePosition?: string }
}

export const cmsPricesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCmsPrices: build.query<{ content: IPriceItem[], totalPages: number }, IPriceQueryParams>({
            query: (params) => ({
                url: `/api/cms/prices/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    sort: JSON.stringify(params.sort),
                    search: params.search,
                    filter: JSON.stringify(params.filter)
                }
            }),
            providesTags: [{type: 'Price'}],
        }),
        updatePrice: build.mutation<IServicePosition, IPriceItem & { _id: string }>({
            query: (payload) => ({
                url: '/api/cms/prices/update',
                method: "POST",
                body: payload
            }),
            transformResponse: (response: IServicePosition) => ({
                ...response,
                title: response.title,
                description: response.description,
            }),
            invalidatesTags: [{type: 'Price'}]
        }),
        deletePrice: build.mutation<any, { id: string }>({
            query: (payload) => ({
                url: '/api/cms/prices/delete',
                method: "POST",
                body: payload
            })
        })
    }),

})

export const {
    useGetCmsPricesQuery,
    useUpdatePriceMutation,
    useDeletePriceMutation,
} = cmsPricesApi
