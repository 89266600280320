import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import counterReducer from '../components/counter/counterSlice';
import authorizationReducer from '../pages/authorization/authorization.slice'
import orderReducer from './orders.slice'
import appReducer from "./app.slice";
import {api} from "../api/api";

export const store = configureStore({
    reducer: {
        app: appReducer,
        authorization: authorizationReducer,
        order: orderReducer,
        [api.reducerPath]: api.reducer,
        counter: counterReducer
    },
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware().concat(api.middleware)
    )

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
