import React, {useEffect} from "react";
import {
    Button,
    createStyles,
    Divider,
    Group,
    Modal,
    NumberInput,
    Paper,
    Radio,
    Select,
    Textarea,
    Text, ActionIcon
} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {IconHistory} from "@tabler/icons-react";

import {IOrder} from "../../api/types";
import {useStatusForm, StatusFormProvider, IOrderStatusForm} from './form-context'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useGetExecutorsQuery} from "../../api/executor";
import ExpensesForm from "./Expenses";
import GuaranteeForm from "./GuaranteeForm";
import {useUpdateOrderStatusMutation} from "../../api/order";
import {isErrorWithMessage} from "../../api/api";
import {openOrderHistory} from "../../app/orders.slice";
import {useGetOutsourceQuery} from "../../api/outsource";

const statusObjects = [
    {value: 'NEW', label: "Новый"},
    {value: 'diagnosed', label: 'Продиагностирован'},
    {value: 'approval', label: "На согласовании"},
    {value: 'needAttention', label: "Требует внимание"},
    {value: 'waitForPieces', label: "Ожидание З/Ч"},
    {value: 'outsource', label: "Аутсорс"},
    {value: 'inProgress', label: "В работе"},
    {value: 'refused', label: 'Отказ'},
    {value: 'done', label: "Готов"},
    {value: 'payed', label: "Оплачен"}

]

const useStyles = createStyles(() => ({
    inRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))

const OrderStatusForm = ({opened, record, close}: { record?: IOrder, opened: boolean, close: () => void }) => {
    const {user, selectedOffice} = useAppSelector(state => state.authorization)
    const dispatch = useAppDispatch();
    const {classes} = useStyles();
    const form = useStatusForm({
        initialValues: record ? {
            _id: record._id,
            status: record.status,
            executor: record.executor._id || "",
            manager: user.userId || "",
            office: selectedOffice,
            price: record.price,
            paymentType: record.paymentType,
            commission: record.commission,
            isOtherExpenses: record.isOtherExpenses,
            otherExpenses: record.otherExpenses || record.expenses,
            otherExpensesComment: record.otherExpensesComment || "",
            outsource: record.outsource,
            guarantee: record.guarantee || {}
        } : {}
    })
    const [updateStatus] = useUpdateOrderStatusMutation();
    const {data = {content: []}, isFetching} = useGetExecutorsQuery({
        page: 1, pageSize: 5
    }, {skip: !opened});
    const {data: outsourceData = {content: []}} = useGetOutsourceQuery({
        page: 1, pageSize: 5
    }, {skip: Boolean(!opened && form.getInputProps('status').value === 'outsource')});

    useEffect(() => {
        if (opened && record) {
            form.setValues({
                _id: record._id,
                status: record.status,
                executor: record.executor._id || "",
                manager: user.userId || "",
                office: selectedOffice,
                price: record.price,
                paymentType: record.paymentType,
                commission: record.commission,
                expenses: record.expenses,
                otherExpenses: record.otherExpenses || record.expenses,
                isOtherExpenses: record.isOtherExpenses,
                otherExpensesComment: record.otherExpensesComment,
                outsource: record.outsource,
                guarantee: record.guarantee || {}
            })
        } else {
            form.reset();
        }
    }, [opened])

    async function onSubmitForm(values: IOrderStatusForm) {
        try {
            await updateStatus({...values, expenses: values.otherExpenses}).unwrap();
            close();
            notifications.show({color: 'green', message: 'Статус успешно изменен'})
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    return (
        <Modal opened={opened} onClose={close}
               size={"xl"}
               centered
               zIndex={200}
               title={<div style={{display: 'flex', alignItems: 'center'}}>
                   <Text>Смена статуса заказа № {record?.orderNo}</Text>
                   <ActionIcon ml={30} onClick={() => {
                       dispatch(openOrderHistory(record?._id))
                   }}>
                       <IconHistory/>
                   </ActionIcon>

               </div>}
        >
            <StatusFormProvider form={form}>
                <form onSubmit={form.onSubmit(async (values) => {
                    await onSubmitForm(values)
                })}>
                    <div className={classes.inRow}>
                        <Select
                            style={{width: '45%'}}
                            label={'Статус'}
                            data={statusObjects}
                            {...form.getInputProps('status')}
                            withinPortal
                        />
                        <Select
                            style={{width: '45%'}}
                            label="Мастер"
                            required={true}
                            searchable
                            placeholder="Выбирите мастера"
                            data={data.content.map(item => {
                                return {label: item.name, value: item._id || ""}
                            })}
                            {...form.getInputProps('executor')}
                        />
                    </div>
                    {form.getInputProps('status').value === 'outsource' && (
                        <Paper mt={10}>
                            <Select
                                style={{width: '45%'}}
                                label="Аутсорс сервис"
                                required={true}
                                searchable
                                data={outsourceData.content.map(item => {
                                    return {label: item.name, value: item._id || ""}
                                })}
                                {...form.getInputProps('outsource')}
                            />
                        </Paper>
                    )}
                    <div style={{marginTop: 10}} className={classes.inRow}>
                        <NumberInput
                            style={{width: '45%'}}
                            label={'Цена ремонта'}
                            {...form.getInputProps('price')}
                        />
                        {form.getInputProps('status').value === 'payed' && (
                            <Select
                                style={{width: '45%'}}
                                label={'Тип Оплаты'}
                                data={[{value: 'cash', label: 'Нал'}, {value: 'card', label: 'Безнал'}, {value: 'transfer', label: "Безнал к."}]}
                                {...form.getInputProps('paymentType')}
                            />
                        )}
                    </div>
                    <div>
                        {(form.getInputProps('status').value === 'payed' && form.getInputProps('paymentType').value === 'card') && (
                            <Paper mt={10} pl={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <div style={{width: '45%'}}></div>
                                <div style={{width: '45%'}}>
                                    <Radio.Group
                                        name="favoriteFramework"
                                        label="Комиссия"
                                        {...form.getInputProps('commission')}
                                    >
                                        <Group mt="xs">
                                            <Radio value={0} label="0%"/>
                                            <Radio value={1} label="1%"/>
                                            <Radio value={2} label="2%"/>
                                        </Group>
                                    </Radio.Group>
                                </div>

                            </Paper>
                        )}
                    </div>
                    {form.getInputProps('status').value === 'done' && (
                        <>
                            <ExpensesForm/>
                            <GuaranteeForm/>
                        </>
                    )}
                    <Paper mt={20}>
                        <Textarea
                            label={'Комментарий'}
                            {...form.getInputProps('comment')}
                        />
                    </Paper>
                    <Divider my={30}/>
                    <Paper className={classes.actionsContainer}>
                        <Button type={'submit'}>Сохранить</Button>
                    </Paper>
                </form>
            </StatusFormProvider>
        </Modal>
    )
}
export default OrderStatusForm;
