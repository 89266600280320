import {api} from "../api";
import {IServiceQueryParams} from "../../pages/cms/types";
export const cmsGalleryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCmsGallery: build.query<{ totalPages: number, content: {url: string, _id: string, name?: string}[] }, IServiceQueryParams>({
            query: (params) => ({
                url: `/api/cms/gallery/query`,
                params: {
                    page: params.page,
                    sort: JSON.stringify(params.sort),
                    pageSize: params.pageSize
                }
            }),
            providesTags: [{ type: 'Gallery'}],
        }),
        createGalleryItem: build.mutation<{ data: {url: string} }, any>({
            query: (payload) => ({
                url: '/api/cms/gallery/create',
                method: "POST",

                formData: true,
                body: payload
            }),
            transformResponse: (response: {data: {url: string}}) => ({
                ...response
            }),
            invalidatesTags: [{type: 'Gallery'}]
        }),
        readGalleryItem: build.query<{url: string}, {_id?: string}>({
            query: (params) => ({
                url: `/api/cms/gallery/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: [{ type: 'Gallery' }],
        }),
    })
})

export const {
    useGetCmsGalleryQuery,
    useReadGalleryItemQuery,
    useCreateGalleryItemMutation
} = cmsGalleryApi
