import {api} from "./api";
import {ICrudResponse, IQueryParams} from "./types";
export interface IOffice {
    _id: string,
    name: string,
    address: string
}

export interface IOfficeCreate {
    name: string,
    address: string
}

interface IOfficeQuery {
    content: IOffice[],
    currentPage: number,
    totalElements: number,
    totalPages: number,
}

export const officeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOffices: build.query<IOfficeQuery, IQueryParams>({
            query: (params) => ({
                url: `/api/office/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Office', tag}],
        }),
        createOffice: build.mutation<ICrudResponse, IOfficeCreate>({
            query: (payload) => ({
                url: '/api/office/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Office'}]
        }),
        updateOffice: build.mutation<ICrudResponse, IOffice>({
            query: (payload) => ({
                url: '/api/office/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Office'}]
        }),
        readOffice: build.query<IOffice, { _id?: string }>({
            query: (params) => ({
                url: `/api/office/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Office', tag}],
        }),
        removeOffice: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/office/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'Office'}]
        })
    })
})

export const {
    useCreateOfficeMutation,
    useGetOfficesQuery,
    useReadOfficeQuery,
    useRemoveOfficeMutation,
    useUpdateOfficeMutation
} = officeApi