import React from 'react';
import {createStyles, Select} from "@mantine/core";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {changeOffice} from "../../pages/authorization/authorization.slice";

const useStyles = createStyles((theme) => ({
    selectInput: {
        maxWidth: 200,
        [theme.fn.smallerThan('lg')]: {
            maxWidth: 120
        },
        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginBottom: 10
        }
    },
}))
const OfficeSelector = () => {
    const selectedOffice = useAppSelector(state => state.authorization.selectedOffice)
    const offices = useAppSelector(state => state.authorization.user.offices)
    const dispatch = useAppDispatch();
    const {classes} = useStyles();

    function onChangeSelection(v: string) {
        dispatch(changeOffice(v))
    }

    return (
        <Select
            className={classes.selectInput}
            value={selectedOffice}
            onChange={onChangeSelection}
            data={offices.map(o => ({label: o.name, value: o._id}))}
            transitionProps={{transition: 'pop-top-left', duration: 80, timingFunction: 'ease'}}
        />
    );
};

OfficeSelector.propTypes = {};

export default OfficeSelector;