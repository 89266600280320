import {useState} from "react";
import {useDebouncedState} from "@mantine/hooks";
import {createFormContext} from "@mantine/form";
import DefaultTable from "../../components/DefaultTable";
import {
    useCreateExecutorMutation,
    useGetExecutorsQuery,
    useRemoveExecutorMutation,
    useUpdateExecutorMutation
} from "../../api/executor";
import {IExecutor} from "../../api/types";
import {notifications, showNotification} from "@mantine/notifications";
import {isErrorWithMessage} from "../../api/api";
import PageContainer from "../../components/PageContainer";
import ExecutorForm from "./ExecutorForm";
import CrudModal from "../../components/CrudModal";
export const [ExecutorFormProvider, useFormContext, useExecutorForm] = createFormContext<IExecutor>();

const initialModalState = {
    opened: false
}

const Executors = () => {
    const [modalState, setModalState] = useState<{ opened: boolean, record?: IExecutor }>(initialModalState)
    const [createExecutor] = useCreateExecutorMutation();
    const [updateExecutor] = useUpdateExecutorMutation();
    const [removeExecutor] = useRemoveExecutorMutation();
    const [page, setPage] = useState(1)
    const [search, setSearch] = useDebouncedState('', 200);
    const {data = {content: [], totalPages: 0}, isFetching} = useGetExecutorsQuery({page, pageSize: 10, search})
    const form = useExecutorForm({
        initialValues: {
            _id: '',
            name: '',
            phone: ''
        }
    })

    function onRowClick(record: IExecutor) {
        setModalState({opened: true, record})
    }

    async function onSubmit(values: IExecutor) {
        try {
            if (values._id) {
                await updateExecutor(values).unwrap()
                showNotification({color: 'green', message: 'Исполнитель обновлен'})
                onClose();
                return;
            }
            await createExecutor(values).unwrap()
            showNotification({color: 'green', message: 'Исполнитель создан'})
            onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                notifications.show({color: 'red', message: e.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }

    }

    async function onDelete() {
        if (modalState && modalState.record && modalState.record._id) {
            await removeExecutor({_id: modalState.record._id})
            onClose();
            showNotification({color: 'green', message: 'Исполнитель удален'})
        }
    }

    function onClose() {
        setModalState((initialModalState))
        form.reset();
    }

    return (
        <PageContainer>
        <DefaultTable
            data={data}
            columns={[{name: 'name', label: 'Имя'}]}
            onChangePage={setPage}
            isFetching={isFetching}
            page={page}
            onSearch={setSearch}
            enableSearch={true}
            searchPlaceholder={'Поиск по всем полям'}
            onRowClick={onRowClick}
            creatable={true}
            onClickCreate={() => setModalState({opened: true})}
        />
            <CrudModal opened={modalState.opened}
                       onClose={onClose} onDelete={onDelete}
                       isUpdate={Boolean(modalState.record)}
                       formId={'executorForm'}
            >
                <ExecutorFormProvider form={form}>
                    <ExecutorForm recordId={modalState.record?._id} onSubmit={onSubmit}/>
                </ExecutorFormProvider>
            </CrudModal>
        </PageContainer>
    )
}

export default Executors;