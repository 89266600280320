import {api} from "../api";
import {IServiceCategory, IServiceQueryParams} from "../../pages/cms/types";
import {ICrudResponse} from "../types";

export const cmsServicesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCmsServices: build.query<{ content: IServiceCategory[], totalPages: number }, IServiceQueryParams>({
            query: (params) => ({
                url: `/api/cms/services/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    sort: JSON.stringify(params.sort),
                    search: params.search
                }
            }),
            providesTags: [{type: 'ServiceCategory'}],
        }),
        readCmsService: build.query<IServiceCategory, { _id: string }>({
            query: (params) => ({
                url: `/api/cms/services/read`,
                method: 'POST',
                body: {
                    id: params._id
                }
            }),
            providesTags: [{type: 'ServiceCategory'}],
        }),
        createService: build.mutation<IServiceCategory, IServiceCategory>({
            query: (payload) => ({
                url: '/api/cms/services/create',
                method: "POST",
                body: payload
            }),
            transformResponse: (response: IServiceCategory) => ({
                _id: response._id,
                title: response.title,
                description: response.description
            }),
            invalidatesTags: [{type: 'ServiceCategory'}]
        }),
        updateService: build.mutation<IServiceCategory, IServiceCategory>({
            query: (payload) => ({
                url: '/api/cms/services/update',
                method: "POST",
                body: payload
            }),
            transformResponse: (response: IServiceCategory) => ({
                _id: response._id,
                title: response.title,
                description: response.description
            }),
            invalidatesTags: [{type: 'ServiceCategory'}]
        }),
        deleteService: build.mutation<ICrudResponse, {_id: string}>({
            query: (payload) => ({
                url: '/api/cms/services/delete',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'ServiceCategory'}]
        }),
        reorderService: build.mutation<ICrudResponse, {destinationId: string, sourceId: string}>({
            query: (payload) => ({
                url: '/api/cms/services/reorder',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'ServiceCategory'}]
        }),
    })
})

export const {
    useGetCmsServicesQuery,
    useUpdateServiceMutation,
    useReadCmsServiceQuery,
    useCreateServiceMutation,
    useDeleteServiceMutation,
    useReorderServiceMutation
} = cmsServicesApi
