import {
    Paper,
    createStyles,
    TextInput,
    PasswordInput,
    Button,
    Title
} from '@mantine/core';
import {useForm} from "@mantine/form";
import {signInAsync, SignInPayload} from "./authorization.slice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    wrapper: {
        height: '100%',
        backgroundSize: 'cover',
        backgroundImage:
            'url(https://unsplash.com/photos/WiONHd_zYI4/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjc1MTY5Mjg3&force=true&w=1920)',
    },

    form: {
        borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
        }`,
        height: '100%',
        maxWidth: 450,
        paddingTop: 80,
        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%'
        }

    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    logo: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        width: 120,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const Login = () => {
    const {classes} = useStyles();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {loading} = useAppSelector(state => state.authorization)
    const form = useForm({
        initialValues: {
            username: '',
            password: '',
        },
    });

    async function onSubmit(values: SignInPayload) {
        const res = await dispatch(signInAsync(values))
        if (res.payload && typeof res.payload === 'object') {

            //navigate to content manager main page
            if (res.payload.role === 'content_manager') {
                navigate('/cms/services')
            }
        }
    }

    return (
        <div className={classes.wrapper}>
            <Paper className={classes.form} radius={0} p={30}>
                <Title order={2} className={classes.title} align="center" mt="md" mb={50}>
                    Добро пожаловать в Apple4you
                </Title>
                <form onSubmit={form.onSubmit((values) => {
                    onSubmit(values)
                })}>
                    <TextInput label="Логин" placeholder="hello@gmail.com" size="md"
                               {...form.getInputProps('username')}
                    />
                    <PasswordInput label="Пароль" placeholder="Ваш пароль" mt="md" size="md"
                                   name={"password"}
                                   {...form.getInputProps('password')}
                    />
                    <Button fullWidth mt="xl" size="md" type={"submit"} loading={loading}>
                        Войти
                    </Button>
                </form>
            </Paper>
        </div>

    );
}
export default Login