import {IUser, useReadUserQuery} from "../../api/user";
import {useGetOfficesQuery} from "../../api/office";
import {useEffect} from "react";
import {Group, MultiSelect, Select, TextInput} from "@mantine/core";
import {useFormContext} from "./Users";
import {useGetRolesQuery} from "../../api/role";

const UserForm = (props: { recordId?: string, onSubmit: (user: IUser) => void }) => {
    const {recordId, onSubmit} = props;
    const {data: roles = []} = useGetRolesQuery({});
    const {data = {content: []}, isFetching} = useGetOfficesQuery({page: 1, pageSize: 10});
    const {data: userRecord, isFetching: isFetchingUser} = useReadUserQuery({_id: recordId}, {skip: !Boolean(recordId)})

    const form = useFormContext();

    useEffect(() => {
        if (userRecord && userRecord._id) {
            form.setValues(userRecord)
        }
    }, [userRecord])

    return (
        <form id={"userForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values)
        })}>
            <Group grow>
                <TextInput
                    label={'Логин'}
                    required
                    {...form.getInputProps('username')}
                />
                <TextInput
                    label={'Имя'}
                    required
                    {...form.getInputProps('name')}
                />
            </Group>
            <Group grow mt={10}>
                <TextInput
                    label={'E-mail'}
                    required
                    {...form.getInputProps('email')}
                />
                <Select
                    label={'Роль'}
                    data={roles}
                    required
                    {...form.getInputProps('role')}
                />
            </Group>
            <MultiSelect
                mt={10}
                label={'Офисы'}
                required
                withinPortal
                data={data.content.map(item => {
                    return {value: item._id, label: item.name}
                })}
                {...form.getInputProps('offices')}
            />
        </form>
    )
}
export default UserForm