import {api} from "./api";
import {ICrudResponse, IQueryParams} from "./types";
import {IOffice} from "./office";

export interface IUserCreate {
    username: string,
    name: string,
    email: string,
    password?: string,
    role: string,
    offices: IOffice[]
}
export interface IUser {
    _id?: string,
    username: string,
    name: string,
    email: string,
    password?: string,
    role: string,
    offices: IOffice[]
}

interface IUserQuery {
    content: IUser[],
    currentPage: number,
    totalElements: number,
    totalPages: number,
}
export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query<IUserQuery, IQueryParams>({
            query: (params) => ({
                url: `/api/user/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'User', tag}],
        }),
        createUser: build.mutation<ICrudResponse, IUserCreate>({
            query: (payload) => ({
                url: '/api/user/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'User'}]
        }),
        updateUser: build.mutation<ICrudResponse, IUser>({
            query: (payload) => ({
                url: '/api/user/update',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'User'}]
        }),
        readUser: build.query<IUser, { _id?: string }>({
            query: (params) => ({
                url: `/api/user/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'User', tag}],
        }),
        removeUser: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/user/delete',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'User'}]
        })
    })
})

export const {
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetUsersQuery,
    useReadUserQuery,
    useRemoveUserMutation
} = userApi
