import {api} from "../../api/api";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserDetails: build.query({
            query: (userId) => ({
                url:`/api/user/read`,
                method: 'POST',
                body: {id: userId}
            })
        })
    })
})

export const {
    useGetUserDetailsQuery
} = authApi