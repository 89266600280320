import {api} from "./api";
import {ICrudResponse, IClient, IQueryParams} from "./types";

export const clientApi = api.injectEndpoints({
    endpoints: (build) => ({
        getClients: build.query<{ content: IClient[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/client/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Client', tag}],
        }),
        createClient: build.mutation<ICrudResponse, IClient>({
            query: (payload) => ({
                url: '/api/client/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Client'}]
        }),
        updateClient: build.mutation<ICrudResponse, IClient>({
            query: (payload) => ({
                url: '/api/client/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Client'}]
        }),
        readClient: build.query<IClient, { _id?: string }>({
            query: (params) => ({
                url: `/api/client/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Client', tag}],
        }),
        removeClient: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/client/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'Client'}]
        })
    })
})

export const {
    useCreateClientMutation,
    useUpdateClientMutation,
    useRemoveClientMutation,
    useReadClientQuery,
    useGetClientsQuery,
} = clientApi