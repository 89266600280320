import {useFormContext} from "./form-context";
import {Checkbox, createStyles, Divider, NumberInput, Paper, Select, Text} from "@mantine/core";

const useStyles = createStyles(() => ({
    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'start'
    }
}))
const GuaranteeForm = () => {
    const form = useFormContext();
    const {classes} = useStyles();
    return (
        <Paper mt={30}>
            <Text>Гарантия</Text>
            <Divider mb={20}/>
            <div className={classes.containerRow}>
                <Checkbox
                    label={'Без гарантии'}
                    {...form.getInputProps('guarantee.withoutGuarantee')}
                />
            </div>
            <Paper mt={20} className={classes.containerRow}>
                <NumberInput
                    label={'Количество'}
                    disabled={form.getInputProps('guarantee.withoutGuarantee').value}
                    {...form.getInputProps('guarantee.number')}
                />
                <Select
                    ml={5}
                    label={'Тип'}
                    disabled={form.getInputProps('guarantee.withoutGuarantee').value}
                    data={[
                        {value: "month", label: "Месяц"},
                        {value: "week", label: "Неделя"},
                        {value: "day", label: "День"},
                    ]}
                    {...form.getInputProps('guarantee.type')}
                />
            </Paper>
        </Paper>
    )

}
export default GuaranteeForm;
