import {Tabs} from "@mantine/core";
import {IconMoneybag, IconHistory} from "@tabler/icons-react";
import HistoryTransactions from "./HistoryTransactions";
import CashBox from "./CashBox";

const Finances = () => {
    return(
        <Tabs defaultValue="cashbox">
            <Tabs.List>
                <Tabs.Tab value="cashbox" icon={<IconMoneybag size="1rem" />}>Касса</Tabs.Tab>
                <Tabs.Tab value="historyTransactions" icon={<IconHistory size="1rem" />}>История транзацкий</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="cashbox" pt="xs">
                <CashBox/>
            </Tabs.Panel>

            <Tabs.Panel value="historyTransactions" pt="xs">
                <HistoryTransactions/>
            </Tabs.Panel>
        </Tabs>
    )
}
export default Finances