import {useState} from "react";
import {useGetExecutorsQuery} from "../../api/executor";
import {Checkbox, createStyles, Divider, NumberInput, Paper, Select, Switch, Text, Textarea} from "@mantine/core";
import {useFormContext} from "./form-context";

const useClientStyles = createStyles((theme) => ({
    dropdownItem: {
        padding: 5,
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.gray[2]
        }
    },
    rowContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: '10px 0'
    },
    divider: {
        padding: "5px 0 10px 0"
    }
}))
const Other = () => {
    const form = useFormContext();
    const [isPrepayment, setIsPrepayment] = useState<boolean>(false);
    const {classes} = useClientStyles();
    const {data = {content: []}, isFetching} = useGetExecutorsQuery({
        page: 1, pageSize: 5
    });

    return (
        <Paper p={"xs"}>
            <div><Text>Прочее</Text></div>
            <Divider className={classes.divider} color={'gray.1'}/>

            <div className={classes.rowContainer}>
                <NumberInput
                    style={{width: '45%'}}
                    label="Предварительная стоимость"
                    {...form.getInputProps('price')}
                />
                <Select
                    label="Мастер"
                    required={true}
                    searchable
                    placeholder="Выбирите мастера"
                    data={data.content.map(item => {
                        return {label: item.name, value: item._id || ""}
                    })}
                    {...form.getInputProps('executor')}
                />
                <Switch
                    label="Срочно"
                    {...form.getInputProps('urgently')}
                />
            </div>
            <div className={classes.rowContainer}>
                <Checkbox
                    checked={isPrepayment}
                    onChange={(event) => setIsPrepayment(event.currentTarget.checked)}
                    labelPosition={"right"}
                    size={"sm"}
                    label="Предоплата"
                />
                {isPrepayment && (
                    <>
                        <Select
                            label="Тип Оплаты"
                            data={[
                                {value: 'cash', label: 'Нал'},
                                {value: 'card', label: 'Безнал'},
                            ]}
                            {...form.getInputProps('paymentType')}
                        />
                        <NumberInput
                            style={{width: '45%'}}
                            label="Предоплата"
                            {...form.getInputProps('prepayment')}
                        />
                    </>
                )}
            </div>
            <Textarea
                label={'Описание проблемы'}
                {...form.getInputProps('problem')}
            />
        </Paper>
    )
}
export default Other