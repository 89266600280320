import {api} from "./api";
import {ICrudResponse, IQueryParams} from "./types";
import {socket} from "./socket";
import {feedbackNotification} from "../utils/feedbackNotification";
import {IServicePosition} from "../pages/cms/types";

export interface IFeedback {
    _id: string,
    name: string,
    phone: string,
    servicePosition?: IServicePosition,
    serviceType?: {
        title: string,
        defaultPrice: number,
        isPromoted: boolean,
        promotedPrice: number,
        servicePosition: IServicePosition,
    },
    service?: {
        title: string,
    },
    applicationType: 'backCall' | 'servicePrice' | 'application' | 'repairDevice',
    message?: string,
    viewedBy?: {
        username: string,
        name: string,
        email: string
    }
}

export const feedbackApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFeedbacks: build.query<{ content: IFeedback[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/cms/feedback/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Feedback', id: 'LIST', tag}],
        }),
        countUnread: build.query<number, {}>({
            query: () => ({
                url: `/api/cms/feedback/countUnread`,
            }),
            async onCacheEntryAdded(
                arg,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
            ) {
                try {
                    await cacheDataLoaded
                    socket.on('countFeedbacks', (data: number) => {
                        updateCachedData((draft) => {
                            if (draft < data) {
                                feedbackNotification();
                            }
                            return data;
                        })
                    })

                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                await cacheEntryRemoved

            },
            providesTags: (result, error, tag) => [{type: 'Feedback', tag}],

        }),
        updateFeedback: build.mutation<ICrudResponse, IFeedback>({
            query: (payload) => ({
                url: '/api/cms/feedback/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Feedback'}]
        }),
        readFeedback: build.query<IFeedback, { _id?: string }>({
            query: (params) => ({
                url: `/api/cms/feedback/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Feedback', tag}],
        }),
        removeFeedback: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/cms/feedback/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'Feedback'}]
        })
    })
})

export const {
    useUpdateFeedbackMutation,
    useRemoveFeedbackMutation,
    useReadFeedbackQuery,
    useGetFeedbacksQuery,
    useCountUnreadQuery
} = feedbackApi