import React, {useRef, useState} from 'react';
import {Box, Button, FileButton, Flex, Grid, Group, Image, Modal, Pagination, Paper, Text} from "@mantine/core";
import {useCreateGalleryItemMutation, useGetCmsGalleryQuery} from "../../api/cms/cms-gallery";
import Images from "./Images";

interface IProps {
    isOpen: boolean,
    onClose: () => void,
    onSelect: (imagePath: string) => void
}

const CmsGallery = (props: IProps) => {
    const {isOpen, onSelect, onClose} = props;

    function onSelectImage(url: string) {
        onSelect(url)
        onClose();
    }

    return (
        <Modal
            onClose={onClose}
            opened={isOpen}
            centered
            size={'100%'}
            title={'Загруженные изображения'}
            style={{
                height: 300
            }}
        >
            <Images
                onSelect={onSelectImage}
            />
            <UploadImage/>
        </Modal>
    );
};

export default CmsGallery;

export const UploadImage = () => {
    const [createGalleryItem] = useCreateGalleryItemMutation();
    const [file, setFile] = useState<File | null>(null);
    const resetRef = useRef<() => void>(null);

    function uploadFile(fileObj: File) {
        try {
            const formData = new FormData();
            setFile(file)
            formData.append('file', fileObj)
            createGalleryItem(formData).unwrap();
        } catch (e) {

        }
    }


    const clearFile = () => {
        setFile(null);
        resetRef.current?.();
    };

    return (
        <>
            <Group position="center" my={10}>
                <FileButton resetRef={resetRef} onChange={uploadFile} accept="image/png,image/jpeg">
                    {(props) => <Button {...props}>Загрузить</Button>}
                </FileButton>
                <Button disabled={!file} color="red" onClick={clearFile}>
                    Сбросить
                </Button>
            </Group>

            {file && (
                <Text size="sm" align="center" mt="sm">
                    Picked file: {file.name}
                </Text>
            )}
        </>
    )
}
