import {api} from "../api";
import {IServicePosition} from "../../pages/cms/types";

export type TServicePositionsCategory = {
    _id: string,
    title: string,
    service: string
}
export const cmsServicePositionsCategoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getServicePositionsCategory: build.query<{
            content: TServicePositionsCategory[],
            totalPages: number
        }, {
            service: string
        }>({
            query: (params) => ({
                url: `/api/cms/servicePositionsCategory/query`,
                params: {
                    page: 1,
                    filter: JSON.stringify({service: params.service}),
                    pageSize: 999
                }
            }),
            providesTags: [{type: 'ServicePositionsCategory'}],
        }),
        createServicePositionsCategory: build.mutation<TServicePositionsCategory, IServicePosition>({
            query: (payload) => ({
                url: '/api/cms/servicePositionsCategory/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'ServicePositionsCategory'}]
        }),
        updateServicePositionsCategory: build.mutation<TServicePositionsCategory, IServicePosition>({
            query: (payload) => ({
                url: '/api/cms/servicePositionsCategory/update',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'ServicePositionsCategory'}]
        }),
        deleteServicePositionsCategory: build.mutation<any, { _id: string }>({
            query: (payload) => ({
                url: '/api/cms/servicePositionsCategory/delete',
                method: "POST",
                body: {
                    id: payload._id
                }
            })
        }),
        readServicePositionsCategory: build.query<IServicePosition, { _id?: string }>({
            query: (params) => ({
                url: `/api/cms/servicePositionsCategory/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: [{type: 'ServicePositionsCategory'}],
        }),
    })
})

export const {
    useGetServicePositionsCategoryQuery,
    useCreateServicePositionsCategoryMutation,
    useReadServicePositionsCategoryQuery,
    useUpdateServicePositionsCategoryMutation,
    useDeleteServicePositionsCategoryMutation,
} = cmsServicePositionsCategoryApi
