import {api} from "./api";

export interface OrderMigrated {
    orderNo: number,
    phone: string,
    clientName: string,
    description: string,
    serial: string,
    brandName: string,
    deviceModel: string,
    date_end: string,
    cost: number
}
interface IOrderQuery {
    content: OrderMigrated[],
    currentPage: number,
    totalElements: number,
    totalPages: number,
}

interface IQuery {
    sort?: { column: string, direction: string },
    search?: string,
    page: number,
    pageSize: number,
    phone?: string,
}

export const ordersMigratedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrdersMigrated: build.query<IOrderQuery, IQuery>({
            query: (params) => ({
                url: `/api/ordersMigrated/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    sort: JSON.stringify(params.sort),
                    search: params.search,
                    filter: JSON.stringify({"phone": params.phone})
                }
            }),
            providesTags: (result, error, tag) => [{type: 'OrderMigrated', tag}],
        }),
        readOrderMigrated: build.query<OrderMigrated, {id: string}>({
            query: (params) => ({
                url: `/api/ordersMigrated/read`,
                method: "POST",
                body: {
                    id: params.id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'OrderMigrated', tag}],
        }),
    })
})

export const {
    useGetOrdersMigratedQuery,
    useReadOrderMigratedQuery
} = ordersMigratedApi