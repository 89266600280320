import React from "react";
import {Code, Group, Navbar} from "@mantine/core";
import {createStyles, getStylesRef, rem, NavLink} from '@mantine/core';
import {IconLogout} from '@tabler/icons-react';
import Links from "./Links";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {clearAuthorization} from "../../pages/authorization/authorization.slice";
import {useNavigate} from "react-router-dom";
import OfficeSelector from "../officeSelector/OfficeSelector";
import {useMediaQuery} from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing.md,
        marginBottom: `calc(${theme.spacing.md} * 1.5)`,
        borderBottom: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },
    footer: {
        paddingTop: theme.spacing.md,
        marginTop: theme.spacing.md,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },
    link: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        fontWeight: 400,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,

            [`& .${getStylesRef('icon')}`]: {
                color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            },
        },
    },
    linkIcon: {
        ref: getStylesRef('icon'),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
        marginRight: theme.spacing.sm,
    },
}));
const Sidebar = () => {
    const {classes} = useStyles();
    const isOpenSidebar = useAppSelector(state => state.app.isOpenSidebar)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 500px)');
    return (
        <Navbar width={{sm: 250}} p="md" hidden={!isOpenSidebar} hiddenBreakpoint={"lg"}>
            <Navbar.Section grow style={{
                overflowY: 'auto',
            }}>
                {isSmallScreen && (
                    <OfficeSelector/>
                )}
                <Links/>
            </Navbar.Section>
            <Navbar.Section className={classes.footer}>
                <NavLink className={classes.link} onClick={async (event) => {
                    event.preventDefault()
                    navigate('/')
                    await dispatch(clearAuthorization())
                }}
                         icon={<IconLogout className={classes.linkIcon} stroke={1.5}/>}
                         label={'Выход'}
                />
            </Navbar.Section>
        </Navbar>
    )
}
export default Sidebar;
