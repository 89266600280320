import {fetchBaseQuery, createApi, retry, FetchBaseQueryError} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: "",
    prepareHeaders: (headers, api: any) => {
        const token = api.getState().authorization.userToken
        if (token) {
            // include token in req header
            headers.set('authorization', `Bearer ${token}`)
            return headers
        }
    },
})

const baseQueryWithRetry = retry(baseQuery, {maxRetries: 0})

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    /**
     * `reducerPath` is optional and will not be required by most users.
     * This is useful if you have multiple API definitions,
     * e.g. where each has a different domain, with no interaction between endpoints.
     * Otherwise, a single API definition should be used in order to support tag invalidation,
     * among other features
     */
    reducerPath: 'splitApi',
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithRetry,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: [
        'ServiceCategory',
        'ServicePositions',
        'Gallery',
        'Device',
        'DeviceType',
        'DeviceModel',
        'DeviceBrand',
        'Order',
        'Executor',
        'WhereKnown',
        'Client',
        'DeviceAppearance',
        'Outsource',
        'OrderHistory',
        'Finances',
        'Analytics',
        'Feedback',
        'OrderMigrated',
        'User',
        'Office',
        'PricesTemplate',
        'Dashboard',
        'Role',
        'Price',
        'ServicePositionsCategory'
    ],
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),
})

export const enhancedApi = api.enhanceEndpoints({
    endpoints: () => ({
        getPost: () => 'test',
    }),
})

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { data: { message: string } } {
    return (
        typeof error === 'object' && error != null && 'data' in error && typeof error.data === 'object' && error.data !== null && 'message' in error.data && typeof (error.data as any).message === 'string'
    )
}
