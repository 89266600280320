import {api} from "./api";

interface IDashboard {
    card: number,
    cash: number,
    cashBox: number,
    estimatedIncome: number,
    inProgress: number,
    payed: {
        count: number,
        income: number
    }
}

export const dashboardApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDashboardData: build.query<IDashboard, { office: string, readyDate: { "$gt": string, "$lt": string } }>({
            query: (params) => ({
                url: `api/dashboard/dashboardData`,
                params: {filter: JSON.stringify({office: params.office, readyDate: params.readyDate})}
            }),
            providesTags: (result, error, tag) => [{type: 'Dashboard', tag}],
        }),

    })
})

export const {
    useGetDashboardDataQuery
} = dashboardApi
