import React, {useEffect} from "react";
import {useForm} from "@mantine/form";
import {Checkbox, Textarea, TextInput} from "@mantine/core";
import {IServiceCategory} from "../types";
import {
    useCreateServiceMutation, useDeleteServiceMutation,
    useReadCmsServiceQuery,
    useUpdateServiceMutation
} from "../../../api/cms/cms-services";
import {notifications, showNotification} from "@mantine/notifications";
import {isErrorWithMessage} from "../../../api/api";
import CrudModal from "../../../components/CrudModal";

interface ICreateProps {
    opened: boolean,
    close: () => void,
    selectedRecord: string | undefined
}

const ServiceForm = (props: ICreateProps) => {
    const {opened, close, selectedRecord} = props;
    const [updateService] = useUpdateServiceMutation();
    const [createService] = useCreateServiceMutation();
    const [deleteService] = useDeleteServiceMutation();
    const {data} = useReadCmsServiceQuery({_id: selectedRecord || ""}, {
        skip: !Boolean(selectedRecord)
    })
    const form = useForm({
        initialValues: {
            _id: '',
            title: '',
            icon: '',
            description: '',
            isPublished: false,
        }
    });

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    async function onSubmit(values: IServiceCategory) {
        try {
            selectedRecord ?
                await updateService(values).unwrap() :
                await createService(values).unwrap();
            showNotification({message: values ? 'Услуга обновлена' : 'Услуга создана', color: 'green'})
            close();
            form.reset();
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }
    }

    async function onDelete() {
        try {
            await deleteService({_id: selectedRecord || ""}).unwrap();
            close();
            form.reset();
            showNotification({message: 'Услуга удалена', color: 'green'})
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    return (
        <CrudModal
            opened={opened}
            isUpdate={Boolean(selectedRecord)}
            onClose={close}
            onDelete={onDelete}
            formId={'serviceForm'}
            title={'Услуга'}
        >
            <form id={'serviceForm'} onSubmit={form.onSubmit(async (values) => {
                await onSubmit(values)
            })}>
                <TextInput
                    my={"md"}
                    withAsterisk
                    required
                    label={'Тег услуги'}
                    placeholder={'remont-iphone'}
                    {...form.getInputProps('_id')}
                />
                <TextInput
                    my={"md"}
                    withAsterisk
                    required
                    label={'Название услуги'}
                    placeholder={'Ремонт iPhone, Ремонт iPad ...'}
                    {...form.getInputProps('title')}
                />
                <TextInput
                    my={"md"}
                    withAsterisk
                    required
                    label={'Иконка в меню'}
                    placeholder={'phone_iphone'}
                    {...form.getInputProps('icon')}
                />
                <Textarea
                    my={"md"}
                    withAsterisk
                    required
                    label={'Описание услуги'}
                    placeholder={'Описание'}
                    {...form.getInputProps('description')}
                />
                <Checkbox
                    label={'Опубликован'}
                    {...form.getInputProps('isPublished',  {type: 'checkbox'})}
                />
            </form>
        </CrudModal>
    )
}

export default ServiceForm;
