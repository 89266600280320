import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface AppState {
    currentOffice: string | null,
    offices: {
        id: string,
        name: string,
    }[],
    colorScheme: 'dark' | 'light',
    isOpenSidebar: boolean,
    loadingApp: boolean,
    createOrderStatus: {
        opened: boolean,
        recordId?: string
    },
    predefinedOrderData?: {
        client: {
            phone: string,
            name: string,
            whereKnown?: string | undefined
        }
    }
}

const initialState: AppState = {
    currentOffice: null,
    offices: [],
    colorScheme: localStorage.getItem('colorScheme') === 'dark' ? 'dark' : 'light',
    isOpenSidebar: true,
    loadingApp: true,
    createOrderStatus: {opened: false},

}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleColorScheme: state => {
            const colorScheme = state.colorScheme === 'dark' ? 'light' : 'dark'
            state.colorScheme = colorScheme;
            localStorage.setItem('colorScheme', colorScheme)
        },
        toggleSideBar: (state) => {
            state.isOpenSidebar = !state.isOpenSidebar
        },
        setLoadingApp: (state, action) => {
            state.loadingApp = action.payload;
        },
        openCreateOrder: (state, action) => {
            state.createOrderStatus = {
                opened: true
            }
            if (action.payload) {
                state.predefinedOrderData = action.payload
            }
        },
        openUpdateOrder: (state, action: PayloadAction<{ id: string }>) => {
            state.createOrderStatus = {
                opened: true,
                recordId: action.payload.id
            }
        },
        closeOrderForm: state => {
            state.createOrderStatus = {
                opened: false
            }
        }
    }
})
export const {toggleColorScheme, toggleSideBar, setLoadingApp, openCreateOrder, openUpdateOrder, closeOrderForm} = appSlice.actions
export default appSlice.reducer
