import {useState} from "react";
import {useDebouncedState} from "@mantine/hooks";
import {createFormContext} from "@mantine/form";
import {notifications, showNotification} from "@mantine/notifications";
import DefaultTable from "../../components/DefaultTable";
import {isErrorWithMessage} from "../../api/api";
import PageContainer from "../../components/PageContainer";
import CrudModal from "../../components/CrudModal";
import OfficeForm from "./OfficeForm";

import {
    IOffice,
    useCreateOfficeMutation,
    useGetOfficesQuery,
    useRemoveOfficeMutation,
    useUpdateOfficeMutation
} from "../../api/office";

export const [OfficeFormProvider, useFormContext, useOfficeForm] = createFormContext<IOffice>();

const initialModalState = {
    opened: false
}

const Offices = () => {
    const [modalState, setModalState] = useState<{ opened: boolean, record?: IOffice }>(initialModalState)
    const [createOffice] = useCreateOfficeMutation();
    const [updateOffice] = useUpdateOfficeMutation();
    const [removeOffice] = useRemoveOfficeMutation();
    const [page, setPage] = useState(1)
    const [search, setSearch] = useDebouncedState('', 200);
    const {data = {content: [], totalPages: 0}, isFetching} = useGetOfficesQuery({page, pageSize: 10, search})

    const form = useOfficeForm({
        initialValues: {
            _id: '',
            name: '',
            address: ''
        }
    })

    function onRowClick(record: IOffice) {
        setModalState({opened: true, record})
    }

    async function onSubmit(values: IOffice) {
        try {
            if (values._id) {
                await updateOffice(values).unwrap()
                showNotification({color: 'green', message: 'Офис обновлен'})
                onClose();
                return;
            }
            await createOffice(values).unwrap()
            showNotification({color: 'green', message: 'Офис создан'})
            onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                notifications.show({color: 'red', message: e.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }

    }

    async function onDelete() {
        if (modalState && modalState.record && modalState.record._id) {
            await removeOffice({_id: modalState.record._id})
            onClose();
            showNotification({color: 'green', message: 'Офис удален'})
        }
    }

    function onClose() {
        setModalState((initialModalState))
        form.reset();
    }

    return (
        <PageContainer>
            <DefaultTable
                data={data}
                columns={[
                    {name: 'name', label: 'Имя'},
                    {name: 'address', label: 'Адресс'},
                ]}
                onChangePage={setPage}
                isFetching={isFetching}
                page={page}
                onSearch={setSearch}
                enableSearch={true}
                searchPlaceholder={'Поиск по всем полям'}
                onRowClick={onRowClick}
                creatable={true}
                onClickCreate={() => setModalState({opened: true})}
            />
            <CrudModal opened={modalState.opened}
                       onClose={onClose} onDelete={onDelete}
                       isUpdate={Boolean(modalState.record)}
                       formId={'officeForm'}
            >
                <OfficeFormProvider form={form}>
                    <OfficeForm recordId={modalState.record?._id} onSubmit={onSubmit}/>
                </OfficeFormProvider>
            </CrudModal>
        </PageContainer>
    )
}

export default Offices;