import {api} from "../api";
import {IServicePosition, IServiceQueryParams} from "../../pages/cms/types";
import {ICrudResponse} from "../types";

export const cmsServicePositionsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCmsServicePositions: build.query<{ content: IServicePosition[], totalPages: number }, IServiceQueryParams>({
            query: (params) => ({
                url: `/api/cms/servicePositions/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    sort: JSON.stringify(params.sort),
                    search: params.search,
                    filter: JSON.stringify(params.filter)
                }
            }),
            providesTags: [{type: 'ServicePositions'}],
        }),
        createServicePosition: build.mutation<IServicePosition, IServicePosition>({
            query: (payload) => ({
                url: '/api/cms/servicePositions/create',
                method: "POST",
                body: payload
            }),
            transformResponse: (response: IServicePosition) => ({
                ...response,
                title: response.title,
                description: response.description,
            }),
            invalidatesTags: [{type: 'ServicePositions'}]
        }),
        updateServicePosition: build.mutation<IServicePosition, IServicePosition>({
            query: (payload) => ({
                url: '/api/cms/servicePositions/update',
                method: "POST",
                body: payload
            }),
            transformResponse: (response: IServicePosition) => ({
                ...response,
                title: response.title,
                description: response.description,
            }),
            invalidatesTags: [{type: 'ServicePositions'}]
        }),
        deleteServicePosition: build.mutation<any, {_id: string}>({
            query: (payload) => ({
                url: '/api/cms/servicePositions/delete',
                method: "POST",
                body: {
                    id: payload._id
                }
            })
        }),
        readCmsServicePositions: build.query<IServicePosition, { _id?: string }>({
            query: (params) => ({
                url: `/api/cms/servicePositions/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: [{type: 'ServicePositions'}],
        }),
        reorderServicePositions: build.mutation<ICrudResponse, {destinationId: string, sourceId: string}>({
            query: (payload) => ({
                url: '/api/cms/servicePositions/reorder',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'ServicePositions'}]
        }),
    })
})

export const {
    useGetCmsServicePositionsQuery,
    useCreateServicePositionMutation,
    useReadCmsServicePositionsQuery,
    useUpdateServicePositionMutation,
    useDeleteServicePositionMutation,
    useReorderServicePositionsMutation,
} = cmsServicePositionsApi
