import React, {useState} from "react";
import {DatePickerInput} from "@mantine/dates";
import {IconCalendar, IconReportMoney, IconTrendingUp} from "@tabler/icons-react";
import {ActionIcon, Card, Divider, Paper, SimpleGrid, Skeleton, Text} from "@mantine/core";
import dayjs from "dayjs";
import {useAveragePriceIncomeQuery} from "../../api/analytics";
import {useAppSelector} from "../../app/hooks";

const defaultDates = [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
]
const Incomes = () => {
    const [date, setDate] = useState<[Date | null, Date | null]>([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ]);
    const selectedOffice = useAppSelector(state => state.authorization.selectedOffice)
    const {data, isFetching} = useAveragePriceIncomeQuery({
        filter: {
            office: selectedOffice,
            readyDate: {
                "$gt": dayjs(date[0] || defaultDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                "$lt": dayjs(date[1] || defaultDates[1]).format('YYYY-MM-DDTHH:mm:ss.SSS')
            }
        }
    });
    return (
        <Card sx={{overflow: 'visible'}} py={30}>
            <div style={{justifyContent: 'center', display: 'flex', overflow: 'visible'}}>
                <DatePickerInput
                    style={{width: 292}}
                    icon={<IconCalendar size="1.1rem" stroke={1.5}/>}
                    type="range"
                    placeholder="Выбирите интервал времени"
                    value={date}
                    onChange={setDate}
                    maw={400}
                    valueFormat={"DD-MM-YYYY"}
                    locale={'ru'}
                />
            </div>
            <SimpleGrid cols={2} mt={20}
                        breakpoints={[{maxWidth: '36rem', cols: 1, spacing: 'sm'}]}
            >
                <Paper shadow={"md"} py={20} px={20}>
                    <Text color={"dimmed"}>Средняя прибыль</Text>
                    <Divider my={10}/>
                    {isFetching ? (
                        <Skeleton h={31}/>
                    ) : (
                        <Paper display={"flex"} sx={{alignItems: 'center'}}>
                            <ActionIcon c={'blue.5'}>
                                <IconTrendingUp/>
                            </ActionIcon>
                            <Text pl={10} size={"xl"} c={'blue.5'}>
                                {new Intl.NumberFormat('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }).format(data?.income || 0)}
                            </Text>
                        </Paper>
                    )}
                </Paper>
                <Paper shadow={"md"} py={20} px={20}>
                    <Text color={"dimmed"}>Сердний чек</Text>
                    <Divider my={10}/>
                    {isFetching ? (
                        <Skeleton h={31}/>
                    ) : (
                        <Paper display={"flex"} sx={{alignItems: 'center'}}>
                            <ActionIcon c={'teal.5'}>
                                <IconReportMoney/>
                            </ActionIcon>
                            <Text pl={10} size={"xl"} c={'teal.5'}>
                                {new Intl.NumberFormat('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }).format(data?.check || 0)}
                            </Text>
                        </Paper>
                    )}
                </Paper>
            </SimpleGrid>
        </Card>
    )
}

export default Incomes