import React, {useEffect, useState} from 'react';
import {
    Button,
    createStyles,
    Divider,
    Textarea,
    TextInput,
    Tabs,
    Image,
    FileButton,
    Checkbox, Select
} from "@mantine/core";
import PricesTable from "./PricesTable";

//api
import {
    useCreateServicePositionMutation, useDeleteServicePositionMutation,
    useReadCmsServicePositionsQuery, useUpdateServicePositionMutation
} from "../../../api/cms/cms-service-positions";

//types
import {IServicePosition} from "../types";
import {useNavigate, useParams} from "react-router-dom";
import CmsGallery from "../../../components/cmsGallery/CmsGallery";
import {useCreateGalleryItemMutation} from "../../../api/cms/cms-gallery";
import {ServicePositionsFormProvider, useServicePositionsForm} from "./form-context";
import PageContainer from "../../../components/PageContainer";
import {isErrorWithMessage} from "../../../api/api";
import {notifications, showNotification} from "@mantine/notifications";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";
import {useGetServicePositionsCategoryQuery} from "../../../api/cms/cms-service-positions-category";

const useStyles = createStyles(() => ({
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {padding: '0 5px'},
    modalContent: {
        transform: 'none !important'
    },
    inputsContainer: {
        width: '50%'
    },
    imageContainer: {
        padding: '20px 0',
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column"
    }
}))

const CreateServicePosition = () => {
    const [ConfirmModal, confirmDelete] = useConfirmationModal({})
    const [isOpenGallery, setIsOpenGallery] = useState<boolean>(false)
    const {serviceTag, id} = useParams();
    const [createGalleryItem, {data: createdFile, isLoading: isLoadingFile}] = useCreateGalleryItemMutation();
    const [createPosition] = useCreateServicePositionMutation();
    const [updatePosition] = useUpdateServicePositionMutation()
    const [deletePosition] = useDeleteServicePositionMutation();
    const {data} = useReadCmsServicePositionsQuery({_id: id}, {
        skip: !Boolean(id)
    });
    const {data: categories = {content: []}} = useGetServicePositionsCategoryQuery({
        service: serviceTag || ""
    }, {
        skip: !serviceTag
    })
    const navigate = useNavigate();
    const {classes} = useStyles();

    const form = useServicePositionsForm({
        initialValues: {
            _id: '',
            title: '',
            category: '',
            description: '',
            type: serviceTag || '',
            prices: [],
            imageBanner: null,
            seo: {
                description: '',
                keywords: ''
            },
            isOldModel: false,
            isPublished: false,
        }
    });

    useEffect(() => {
        if (!isLoadingFile && createdFile) {
            console.log(createdFile.data.url)
            form.setValues({imageBanner: createdFile?.data?.url})
        }
    }, [isLoadingFile, createdFile])

    useEffect(() => {
        if (data) {
            form.setValues(data)
        }
    }, [data])

    if (!serviceTag) return <>404</>;


    async function onSubmit(values: IServicePosition) {
        try {
            id ?
                await updatePosition(values).unwrap() :
                await createPosition(values).unwrap()
            form.reset();
            navigate(-1);
            showNotification({color: 'green', message: id ? "Позиция обновлена" : "Позиция создана"})
        } catch (err) {
            console.log(err);
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    async function onDelete() {
        try {
            if (!id) return;

            const isConfirmed = await confirmDelete();

            if(!isConfirmed) return;

            await deletePosition({_id: id}).unwrap();
            form.reset();
            navigate(-1);
            showNotification({color: 'green', message: "Позиция удалена"})
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    function onSelectImage(imagePath: string) {
        form.setValues({imageBanner: imagePath})
    }

    async function uploadFile(fileObj: File) {
        const formData = new FormData();
        formData.append('file', fileObj)
        createGalleryItem(formData)
    }

    const inputs = (
        <div style={{display: 'flex'}}>
            <div className={classes.inputsContainer}>
                <div style={{display: 'flex'}}>
                    <TextInput
                        my={"md"}
                        withAsterisk
                        disabled={Boolean(id)}
                        required
                        label={'Тег'}
                        placeholder={'iPhone14ProMax'}
                        {...form.getInputProps('_id')}
                    />
                    <TextInput
                        mx={'md'}
                        my={"md"}
                        withAsterisk
                        required
                        label={'Название'}
                        placeholder={'Ремонт iPhone 14 Pro Max, Ремонт iPad 8 ...'}
                        {...form.getInputProps('title')}
                    />
                    <Select
                        mx={'md'}
                        my={"md"}
                        label={'Категория'}
                        data={categories.content.map(o => ({label: o.title, value: o._id}))}
                        transitionProps={{transition: 'pop-top-left', duration: 80, timingFunction: 'ease'}}
                        {...form.getInputProps('category')}
                    />
                </div>
                <Textarea
                    my={"xs"}
                    withAsterisk
                    required
                    label={'Описание'}
                    placeholder={'Описание'}
                    {...form.getInputProps('description')}
                />
                <Checkbox
                    mt="md"
                    label="Старая модель"
                    {...form.getInputProps(`isOldModel`, {type: 'checkbox'})}
                />
                <Divider my={20}/>
                <Checkbox
                    mt="md"
                    label="Опубликован"
                    {...form.getInputProps(`isPublished`, {type: 'checkbox'})}
                />
            </div>
            <div className={classes.imageContainer}>
                <Image
                    style={{border: '1px solid gray', cursor: 'pointer'}}
                    width={350}
                    height={500}
                    src={'https://apple4you.ru' + form.getInputProps('imageBanner').value}
                    alt="With default placeholder"
                    withPlaceholder
                />
                <div style={{marginTop: 10}}>
                    <Button onClick={() => setIsOpenGallery(true)}>
                        Выбрать из галлереи
                    </Button>
                    <FileButton onChange={uploadFile}>
                        {props => (
                            <Button style={{marginLeft: 10}} {...props}>
                                Загрузить
                            </Button>
                        )}
                    </FileButton>

                </div>
            </div>
        </div>
    )

    return (
        <PageContainer>
            <form onSubmit={form.onSubmit(async (values) => {
                await onSubmit(values)
            })}>
                <ServicePositionsFormProvider form={form}>
                    <Tabs defaultValue={"description"}>
                        <Tabs.List>
                            <Tabs.Tab value={"description"}>Основные</Tabs.Tab>
                            <Tabs.Tab value={"prices"}>Цены</Tabs.Tab>
                            <Tabs.Tab value={"seo"}>SEO</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value={'description'}>
                            {inputs}
                        </Tabs.Panel>

                        <Tabs.Panel value={"prices"}>
                            <PricesTable/>
                        </Tabs.Panel>

                        <Tabs.Panel value={"seo"}>
                            <Textarea
                                my={"xs"}
                                label={'Описание (meta) ~150 символов'}
                                placeholder={'Описание'}
                                {...form.getInputProps('seo.description')}
                            />
                            <TextInput
                                my={"md"}
                                label={'Ключевые слова (keywords), через запятую, не более 10 слов'}
                                placeholder={'Ремонт iPhone 14 Pro Max, Ремонт Айфонов в Москве'}
                                {...form.getInputProps('seo.keywords')}
                            />
                        </Tabs.Panel>

                        <Divider my={"md"}/>
                        <div className={classes.actionsContainer}>
                            <Button variant={"outline"} mx={5} onClick={() => navigate(-1)}>Отмена</Button>
                            {id && <Button mr={5} bg={'red.5'} onClick={onDelete}>Удалить</Button>}
                            <Button type={"submit"}>{id ? 'Обновить' : 'Создать'}</Button>
                        </div>
                    </Tabs>
                </ServicePositionsFormProvider>
            </form>
            <CmsGallery isOpen={isOpenGallery} onClose={() => setIsOpenGallery(false)} onSelect={onSelectImage}/>
            <ConfirmModal/>
        </PageContainer>
    )
}
export default CreateServicePosition;
