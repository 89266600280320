import {useEffect} from "react";
import {TextInput} from "@mantine/core";
import {useFormContext} from "./Executors";
import {IExecutor} from "../../api/types";
import {useReadExecutorQuery} from "../../api/executor";

const ExecutorForm = (props: { recordId?: string, onSubmit: (value: IExecutor) => void }) => {
    const {recordId, onSubmit} = props;
    const {
        data: officeRecord,
        isFetching: isFetchingOffice
    } = useReadExecutorQuery({_id: recordId}, {skip: !Boolean(recordId)})

    const form = useFormContext();

    useEffect(() => {
        if (officeRecord && officeRecord._id) {
            form.setValues(officeRecord)
        }
    }, [officeRecord])

    return (
        <form id={"executorForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values)
        })}>
            <TextInput
                label={'Имя'}
                required
                {...form.getInputProps('name')}
            />
            <TextInput
                label={'Телефон'}
                {...form.getInputProps('phone')}
            />
        </form>
    )
}
export default ExecutorForm