import {createFormContext} from "@mantine/form";
export interface IOrderStatusForm {
    _id?: string,
    status?: string,
    executor?: string,
    price?: number,
    manager?: string,
    office?: string,
    paymentType?: string,
    commission?: number,
    expenses?: number,
    isOtherExpenses?: boolean,
    otherExpenses?: number,
    otherExpensesComment?: string,
    usedDetails?: any[],
    outsource?: string,
    guarantee?: {
        number?: number,
        type?: string,
        withoutGuarantee?: boolean
    },
    comment?: string,

}

export const [StatusFormProvider, useFormContext, useStatusForm] = createFormContext<IOrderStatusForm>();