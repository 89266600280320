import {useState} from "react";
import {Badge, Button, Divider, Modal, Paper, Skeleton, Text, useMantineTheme} from "@mantine/core";
import {IFeedback, useGetFeedbacksQuery, useReadFeedbackQuery} from "../../api/feedback";
import DefaultTable from "../../components/DefaultTable";
import {useAppDispatch} from "../../app/hooks";
import {openCreateOrder} from "../../app/app.slice";

const applicationTypeExtender = {
    backCall: {
        label: 'Обратный звонок',
        color: 'teal.5'
    },
    servicePrice: {
        label: 'Узнать Цену Ремонта',
        color: 'blue.5'
    },
    application: {
        label: 'Заявка на ремонт',
        color: 'orange.5'
    },
    repairDevice: {
        label: 'Ремонт Устройства',
        color: 'violet.5'
    }
}
const Feedback = () => {
    const [page, setPage] = useState(1);
    const {data, isLoading} = useGetFeedbacksQuery({page: page, pageSize: 10}, {});
    const [modalState, setModalState] = useState<{ opened: boolean, record?: IFeedback }>({opened: false})

    function onRowClick(row: IFeedback) {
        setModalState({
            opened: true,
            record: row
        })
    }

    return (
        <div>
            <DefaultTable
                data={data || {content: [], totalPages: 0}}
                columns={[
                    {
                        name: 'createdAt', label: 'Дата', render: (value) => {
                            return value && new Date(value).toLocaleString()
                        }
                    },
                    {name: 'name', label: 'Клиент', render: (_, record) => {
                        return <>
                            <Text>{record.name}, {record.phone}</Text>
                        </>
                        }},
                    {
                        name: 'applicationType', label: 'Тип заявки', render: value => {
                            const typeObject = applicationTypeExtender[value as (keyof typeof applicationTypeExtender)]
                            return <Badge c={typeObject.color}>{typeObject.label}</Badge>
                        }
                    },
                    {
                        name: 'viewedBy', label: 'Статус', render: value => {

                            return <Badge c={value ? 'orange.8' : 'green.8'}>{value ? 'Обработан' : 'Новая'}</Badge>
                        }
                    },
                ]}
                onChangePage={setPage}
                isFetching={isLoading}
                page={page}
                onRowClick={onRowClick}
            />
            <FeedBackInfo data={modalState.record} opened={modalState.opened}
                          onClose={() => setModalState({opened: false})}/>
        </div>
    )
}
export default Feedback;


const FeedBackInfo = (props: { data?: IFeedback, opened: boolean, onClose: () => void }) => {
    const {data, onClose, opened} = props;
    const theme = useMantineTheme();
    const {data: feedbackRecord} = useReadFeedbackQuery({_id: props.data?._id || ""}, {skip: !data?._id})
    const typeObject = applicationTypeExtender[data?.applicationType as (keyof typeof applicationTypeExtender)];
    const dispatch = useAppDispatch();
    return (
        <Modal opened={opened} onClose={onClose} centered={true}
               size={"xl"}
               overlayProps={{
                   color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                   opacity: 0.55,
                   blur: 3,
               }}
               title={<>Заявка <Badge c={typeObject?.color}>{typeObject?.label}</Badge></>}>
            <Divider mb={20}/>
            {!feedbackRecord ? (
                <>
                    <Skeleton h={30}/>
                    <Skeleton h={30} mt={10}/>
                    <Skeleton h={30} mt={10}/>
                </>
            ) : (
                <Paper>
                    <Paper p={5}>
                        <Text>Имя: {feedbackRecord.name}</Text>
                        <Text pt={10}>Номер телефона: <a href={`tel:${feedbackRecord.phone}`}>{feedbackRecord.phone}</a></Text>
                        <Divider my={10}/>
                        {data?.service && (
                            <div>
                                <Text>Заявка на ремонт <Text weight={500}>{feedbackRecord.service?.title}</Text></Text>
                            </div>
                        )}
                        {data?.servicePosition && (
                            <div>
                                <Text>Заявка на ремонт <Text span
                                                             weight={500}>{feedbackRecord.servicePosition?.title}</Text></Text>
                            </div>
                        )}
                        {data?.serviceType && (
                            <div>
                                <Text>Заявка на ремонт <Text span
                                                             weight={500}>{feedbackRecord.serviceType?.servicePosition?.title}</Text></Text>
                                <Text my={5}>Заказаная услуга: <Text span
                                                                     weight={500}>{feedbackRecord.serviceType?.title}</Text></Text>
                                <Text>Цена: <Text
                                    strikethrough={feedbackRecord.serviceType?.isPromoted}
                                    c={'green.5'}
                                    span weight={500}>
                                    {new Intl.NumberFormat('ru-RU', {
                                        style: 'currency', currency: 'RUB'
                                    }).format(feedbackRecord.serviceType?.defaultPrice || 0)}
                                </Text>
                                </Text>
                                {feedbackRecord.serviceType?.isPromoted && (
                                    <Text my={5}>Цена со скидкой: <Text span c={'red.5'}
                                                                        weight={500}> {new Intl.NumberFormat('ru-RU', {
                                        style: 'currency', currency: 'RUB'
                                    }).format(feedbackRecord.serviceType?.promotedPrice || 0)}
                                    </Text>
                                    </Text>
                                )}
                            </div>
                        )}
                        {feedbackRecord.message && (
                            <>
                                <Text weight={500}>Сообщение:</Text>
                                <Text>{feedbackRecord.message}</Text>
                            </>
                        )}
                        <Paper my={10} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => {
                                dispatch(openCreateOrder({
                                    client: {
                                        phone: feedbackRecord.phone,
                                        name: feedbackRecord.name
                                    }
                                }))
                            }}>Создать заказ</Button>
                        </Paper>
                    </Paper>
                </Paper>
            )}

        </Modal>
    )
}
