import {api} from "./api";
import {ICrudResponse, IWhereKnown, IQueryParams, IWhereKnownCreate} from "./types";

export const whereKnownApi = api.injectEndpoints({
    endpoints: (build) => ({
        getWhereKnown: build.query<{ content: IWhereKnown[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/whereKnown/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'WhereKnown', tag}],
        }),
        createWhereKnown: build.mutation<ICrudResponse, IWhereKnownCreate>({
            query: (payload) => ({
                url: '/api/whereKnown/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'WhereKnown'}]
        }),
        updateWhereKnown: build.mutation<ICrudResponse, IWhereKnown>({
            query: (payload) => ({
                url: '/api/whereKnown/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'WhereKnown'}]
        }),
        readWhereKnown: build.query<IWhereKnown, { _id?: string }>({
            query: (params) => ({
                url: `/api/whereKnown/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'WhereKnown', tag}],
        }),
        removeWhereKnown: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/whereKnown/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'WhereKnown'}]
        })
    })
})

export const {
    useCreateWhereKnownMutation,
    useUpdateWhereKnownMutation,
    useRemoveWhereKnownMutation,
    useReadWhereKnownQuery,
    useGetWhereKnownQuery,
} = whereKnownApi