import {api} from "./api";

type TRole = string;

export const roleApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRoles: build.query<TRole[], any>({
            query: () => ({
                url: `/api/role/query/`,
            }),
            providesTags: [{type: 'Role'}],
        })
    })
})

export const {
    useGetRolesQuery
} = roleApi


const EditableRow = () => {

}