import React from "react";
import OrdersTable from "../../components/ordersTable/OrdersTable";

const Orders = () => {
    return (
        <>
            <OrdersTable
                filter={{
                    status: "refused",
                }}
            />
        </>
    )
}
export default Orders;