import {AppShell, useMantineTheme, LoadingOverlay} from "@mantine/core";
import React, {ReactNode, useEffect} from "react";
import Sidebar from "../sidebar/Sidebar";
import AppHeader from "../header/AppHeader";
import {useMediaQuery} from '@mantine/hooks';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {toggleSideBar} from "../../app/app.slice";
import OrderHistory from "../OrderHistory";

interface Props {
    children?: ReactNode,
    isAuthenticated: boolean
}

const Layout = ({children, isAuthenticated}: Props) => {
    const theme = useMantineTheme();
    const isSmallScreen = useMediaQuery('(max-width: 1199px)');
    const {loadingApp, isOpenSidebar} = useAppSelector(state => state.app)
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isSmallScreen && isOpenSidebar) {
            dispatch(toggleSideBar())
        }
    }, [isSmallScreen, dispatch])

    return (
        <AppShell
            hidden={!isAuthenticated}
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            }}
            layout={isSmallScreen ? "default" : "alt"}
            navbarOffsetBreakpoint="lg"
            asideOffsetBreakpoint="sm"
            navbar={<Sidebar/>}
            header={<AppHeader/>}
        >
            <LoadingOverlay visible={loadingApp} overlayBlur={2}/>
            {children}
            <OrderHistory/>
        </AppShell>
    )
}
export default Layout

Layout.defaultProps = {
    isAuthenticated: false,
}
