import React from 'react';
import {createStyles} from "@mantine/core";

const useStyles = createStyles(() => ({
    container: {
        padding: '10px 3rem 0 2rem'
    }
}))
const PageContainer = (props: {children: React.ReactNode}) => {
    const {classes} = useStyles();
    return(
        <div className={classes.container}>
            {props.children}
        </div>
    )
}
export default PageContainer;