import {Button, Checkbox, Divider, Flex, Modal, NumberInput, Textarea, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {IPriceItem, IServiceCategory} from "../types";
import {useForm} from "@mantine/form";
import {notifications, showNotification} from "@mantine/notifications";
import {isErrorWithMessage} from "../../../api/api";
import {useUpdatePriceMutation} from "../../../api/cms/cms-prices";

type TProps = {
    price: IPriceItem
}

const EditableRow = ({price}: TProps) => {
    const [openEdit, setOpenEdit] = useState(false);
    const [updatePrice] = useUpdatePriceMutation();
    const form = useForm<IPriceItem & { _id: string }>();

    async function onSubmit(values: IPriceItem & { _id: string }) {
        try {

            await updatePrice({
                ...values,
            }).unwrap()

            showNotification({message: 'Цена обновлена', color: 'green'})
            setOpenEdit(false);
            form.reset();
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }
    }

    useEffect(() => {
        if (openEdit) {
            form.setValues(price)
        } else {
            form.reset();
        }
    }, [openEdit])
    return (
        <>
            <tr onClick={() => setOpenEdit(true)}>
                <td>
                    {price.servicePosition}
                </td>
                <td>
                    {price.title}
                </td>
                <td>
                    {price.defaultPrice}
                </td>
                <td>
                    <Flex justify={"space-between"}>
                        <Checkbox
                            disabled
                            checked={price.isPromoted}
                        />
                        {price.promotedPrice || "-"}
                    </Flex>
                </td>
                <td>
                    {price.floatPrice}
                </td>
                <td>
                    {price.time}
                </td>
            </tr>
            <Modal opened={openEdit} onClose={() => setOpenEdit(false)} title={price.servicePosition} centered>
                <form onSubmit={form.onSubmit(async (values) => {
                    await onSubmit(values)
                })}>
                    <TextInput
                        autoFocus={true}
                        my={"md"}
                        withAsterisk
                        required
                        placeholder={'Замена экрана'}
                        {...form.getInputProps(`title`)}
                    />
                    <Textarea
                        my={"md"}
                        withAsterisk
                        required
                        placeholder={'Описание'}
                        {...form.getInputProps(`description`)}
                    />
                    <Flex>
                        <NumberInput
                            disabled={Boolean(form.getInputProps(`isFloat`).value)}
                            type={'number'}
                            withAsterisk
                            required
                            placeholder={'Цена'}
                            {...form.getInputProps(`defaultPrice`)}
                        />
                        <Checkbox
                            disabled={Boolean(form.getInputProps(`isFloat`).value)}
                            mx={10}
                            mt={8}
                            label="Скидка"
                            {...form.getInputProps(`isPromoted`, {type: 'checkbox'})}
                        />
                        <NumberInput
                            disabled={(Boolean(form.getInputProps(`isFloat`).value) || !Boolean(form.getInputProps(`isPromoted`).value))}
                            type={'number'}
                            withAsterisk
                            required
                            placeholder={'Цена со скидкой'}
                            {...form.getInputProps(`promotedPrice`)}
                        />
                    </Flex>
                    <Divider my={10}/>
                    <Flex align={'center'}>
                        <Checkbox
                            mx={10}
                            mt={15}
                            {...form.getInputProps(`isFloat`, {type: 'checkbox'})}
                        />
                        <TextInput
                            w={'100%'}
                            disabled={!Boolean(form.getInputProps(`isFloat`).value)}
                            label={'Плавающая цена'}
                            {...form.getInputProps(`floatPrice`)}
                        />
                    </Flex>
                    <TextInput
                        my={"md"}
                        withAsterisk
                        required
                        placeholder={'Время ремонта'}
                        {...form.getInputProps(`time`)}
                    />
                    <Divider my={10}/>
                    <Flex justify={'flex-end'}>

                        <Button type={'submit'}>
                            Сохранить
                        </Button>
                    </Flex>
                </form>
            </Modal>
        </>
    )
}

export default EditableRow