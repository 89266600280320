import {api} from "./api";
import {ICrudResponse, IDeviceType, IDeviceTypeCreate, IQueryParams} from "./types";

export const deviceTypeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeviceTypes: build.query<{ content: IDeviceType[] }, IQueryParams>({
            query: (params) => ({
                url: `/api/deviceType/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter,
                    sort: JSON.stringify(params.sort)
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceType', tag}],
        }),
        createDeviceType: build.mutation<ICrudResponse, IDeviceTypeCreate>({
            query: (payload) => ({
                url: '/api/deviceType/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceType'}]
        }),
        updateDeviceType: build.mutation<ICrudResponse, IDeviceType>({
            query: (payload) => ({
                url: '/api/deviceType/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceType'}]
        }),
        readDeviceType: build.query<IDeviceType, { _id?: string }>({
            query: (params) => ({
                url: `/api/deviceType/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'DeviceType', tag}],
        }),
        removeDeviceType: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/deviceType/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'DeviceType'}]
        })
    })
})

export const {
    useCreateDeviceTypeMutation,
    useUpdateDeviceTypeMutation,
    useRemoveDeviceTypeMutation,
    useReadDeviceTypeQuery,
    useGetDeviceTypesQuery,
} = deviceTypeApi