import {api} from "./api";
import {ICrudResponse, IOrder, IOrderCreate} from "./types";
import {IOrderStatusForm} from "../components/orderStatusForm/form-context";

interface IOrderQuery {
    content: IOrder[],
    currentPage: number,
    totalElements: number,
    totalPages: number,
}

interface IQuery {
    office: string,
    sort?: { column: string, direction: string },
    readyDate?: {"$gt": string, "$lt": string},
    search?: string,
    status?: string | string[],
    page: number,
    pageSize: number,
    client?: string,
}

export const orderApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrders: build.query<IOrderQuery, IQuery>({
            query: (params) => ({
                url: `/api/order/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    sort: JSON.stringify(params.sort),
                    search: params.search,
                    filter: JSON.stringify({office: params.office, status: params.status, "client._id": params.client, readyDate: params.readyDate})
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Order', tag}],
        }),
        createOrder: build.mutation<ICrudResponse, IOrderCreate>({
            query: (payload) => ({
                url: `/api/order/create`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ['Order']
        }),
        updateOrder: build.mutation<ICrudResponse, IOrderCreate>({
            query: (payload) => ({
                url: `/api/order/update`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ['Order']
        }),
        updateOrderStatus: build.mutation<ICrudResponse, IOrderStatusForm>({
            query: (payload) => ({
                url: `/api/order/changeStatus`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ['Order', 'OrderHistory', 'Dashboard']
        }),
        readOrder: build.query<IOrder, {id: string}>({
            query: (params) => ({
                url: `/api/order/read`,
                method: "POST",
                body: {
                    id: params.id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Order', tag}],
        }),
        getGuaranteeText: build.query<{ text: string }, {id: string}>({
            query: (params) => ({
                url: `/api/order/getGuaranteeText`,
                params: {
                    orderId: params.id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Order', tag}],
        })
    })
})

export const {
    useGetOrdersQuery,
    useCreateOrderMutation,
    useUpdateOrderMutation,
    useUpdateOrderStatusMutation,
    useReadOrderQuery,
    useLazyReadOrderQuery,
    useLazyGetGuaranteeTextQuery
} = orderApi
