import {api} from "./api";
import {ICrudResponse, IExecutor, IQueryParams} from "./types";

export const executorApi = api.injectEndpoints({
    endpoints: (build) => ({
        getExecutors: build.query<{ content: IExecutor[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/executor/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Executor', tag}],
        }),
        createExecutor: build.mutation<ICrudResponse, IExecutor>({
            query: (payload) => ({
                url: '/api/executor/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Executor'}]
        }),
        updateExecutor: build.mutation<ICrudResponse, IExecutor>({
            query: (payload) => ({
                url: '/api/executor/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Executor'}]
        }),
        readExecutor: build.query<IExecutor, { _id?: string }>({
            query: (params) => ({
                url: `/api/executor/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Executor', tag}],
        }),
        removeExecutor: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/executor/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'Executor'}]
        })
    })
})

export const {
    useCreateExecutorMutation,
    useUpdateExecutorMutation,
    useRemoveExecutorMutation,
    useReadExecutorQuery,
    useGetExecutorsQuery,
} = executorApi