import {ActionIcon, createStyles, rem, Text, Title} from "@mantine/core";
import {
    useGetCmsServicePositionsQuery,
    useReorderServicePositionsMutation
} from "../../../api/cms/cms-service-positions";
import {useNavigate, useParams} from "react-router-dom";
import {IServicePosition} from "../types";
import {IconEdit} from "@tabler/icons-react";
import DefaultTable from "../../../components/DefaultTable";
import React, {useState} from "react";
import PageContainer from "../../../components/PageContainer";
import DefaultTableDraggable from "../../../components/DefaultTableDraggable";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";

const useStyles = createStyles(() => ({
    container: {
        display: 'flex',
        padding: '10px 10px'
    },
    item: {
        padding: 15,
        margin: 10
    },
    head: {
        marginBottom: 10
    }
}))
const ServicePositions = () => {
    const [ConfirmModal, confirmDrag] = useConfirmationModal({})
    const {serviceTag} = useParams();
    const navigate = useNavigate();
    const [page, setPage] = useState(1)
    const {data = {content: [], totalPages: 0}, isLoading} = useGetCmsServicePositionsQuery({
        page: page,
        pageSize: 100,
        filter: {'type._id': serviceTag || ""},
        sort: {column: "position", direction: "descend"}
    });
    const [reorderServicePosition] = useReorderServicePositionsMutation();
    const {classes} = useStyles();

    function onClick(data: {}) {

    }

    function onEdit(data: IServicePosition) {
        navigate(`servicePosition${data._id ? '/' + data._id : ''}`)
    }

    async function onDragEnd(destination: number, source: number) {

        const isConfirmed = await confirmDrag();
        if(!isConfirmed) return;

        const destinationItem = data.content[destination];
        const sourceItem = data.content[source];
        //reorder api call
        try {
            const res = await reorderServicePosition({
                destinationId: destinationItem._id,
                sourceId: sourceItem._id
            }).unwrap()

            console.log(res)
        } catch (e) {
            console.log('reorder error', e)
        }
    }

    return (
        <PageContainer>
            <div className={classes.head}>
                <Title order={2}>Позиции Услуг</Title>
            </div>
            <DefaultTableDraggable
                data={data}
                columns={[
                    {name: '_id', label: 'Тег Услуги',},
                    {name: 'title', label: 'Название'},
                    {name: 'category', label: 'Категория', render: (value) => {
                        return value ? value.title : "-"
                        }},
                    {
                        name: 'isPublished', label: 'Состояние', render: (value) => {
                            return (
                                value ? <Text c={'green'}>Опубликован</Text> : <Text c={'red'}>Скрыт</Text>
                            )
                        }
                    },
                    {
                        name: 'actions', label: 'Действия', width: rem(40), render: (value, record) => {
                            return (
                                <>
                                    <ActionIcon onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(record)
                                    }}>
                                        <IconEdit/>
                                    </ActionIcon>
                                </>
                            )
                        }
                    }
                ]}
                isFetching={isLoading}
                onChangePage={setPage}
                page={page}
                creatable
                onClickCreate={() => navigate(`servicePosition`)}
                onRowClick={onClick}
                enableSearch
                onSearch={() => {
                }}
                onDragEnd={onDragEnd}
            />
            <ConfirmModal/>
        </PageContainer>
    )
}
export default ServicePositions;
