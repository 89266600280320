import {useState} from "react";
import {useDebouncedState} from "@mantine/hooks";
import DefaultTable from "../../components/DefaultTable";
import PageContainer from "../../components/PageContainer";
import CrudModal from "../../components/CrudModal";
import {createFormContext} from "@mantine/form";
import {notifications, showNotification} from "@mantine/notifications";
import {isErrorWithMessage} from "../../api/api";
import UserForm from "./UserForm";

import {
    IUser,
    useCreateUserMutation,
    useGetUsersQuery,
    useRemoveUserMutation,
    useUpdateUserMutation
} from "../../api/user";

export const [UserFormProvider, useFormContext, useUserForm] = createFormContext<IUser>();

const initialModalState = {
    opened: false
}

const Users = () => {
    const [modalState, setModalState] = useState<{ opened: boolean, record?: IUser }>(initialModalState)
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useRemoveUserMutation();
    const [page, setPage] = useState(1)
    const [search, setSearch] = useDebouncedState('', 200);
    const {data = {content: [], totalPages: 0}, isFetching} = useGetUsersQuery({page, pageSize: 10, search})

    const form = useUserForm({
        initialValues: {
            username: '',
            name: '',
            email: '',
            offices: [],
            role: ''
        }
    })

    function onRowClick(record: IUser) {
        setModalState({opened: true, record})
    }

    async function onSubmit(values: IUser) {
        try {
            if (values._id) {
                await updateUser(values).unwrap()
                showNotification({color: 'green', message: 'Пользователь обновлен'})
                onClose();
                return;
            }
            await createUser(values).unwrap()
            showNotification({color: 'green', message: 'Пользователь создан'})
            onClose();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                notifications.show({color: 'red', message: e.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }

    }

    async function onDelete() {
        if (modalState && modalState.record && modalState.record._id) {
            await deleteUser({_id: modalState.record._id})
            onClose();
            showNotification({color: 'green', message: 'Пользователь удален'})
        }
    }

    function onClose() {
        setModalState((initialModalState))
        form.reset();
    }

    return (
        <PageContainer>
            <DefaultTable
                data={data}
                columns={[
                    {name: 'username', label: 'Логин'},
                    {name: 'name', label: 'Имя'},
                    {name: 'email', label: 'E-майл'},
                    {name: 'role', label: 'Роль'},
                ]}
                onChangePage={setPage}
                isFetching={isFetching}
                page={page}
                onSearch={setSearch}
                enableSearch={true}
                searchPlaceholder={'Поиск по всем полям'}
                onRowClick={onRowClick}
                creatable={true}
                onClickCreate={() => setModalState({opened: true})}
            />
            <CrudModal opened={modalState.opened}
                       onClose={onClose} onDelete={onDelete}
                       isUpdate={Boolean(modalState.record)}
                       formId={'userForm'}
            >
                <UserFormProvider form={form}>
                    <UserForm recordId={modalState.record?._id} onSubmit={onSubmit}/>
                </UserFormProvider>
            </CrudModal>
        </PageContainer>

    )
}
export default Users;