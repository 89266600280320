import {ActionIcon, Button, Card, createStyles, Flex, getStylesRef, Modal, Skeleton, Text} from "@mantine/core";
import {useDeleteCmsPricesTemplateMutation, useGetCmsPricesTemplateQuery} from "../../../api/cms/cms-prices-template";
import {IPriceItem} from "../types";
import {IconTrashX, IconX} from "@tabler/icons-react";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";

const useStyles = createStyles((theme) => ({
    button: {
        // assign reference to selector
        ref: getStylesRef('button'),
        visibility: "hidden"
    },

    container: {
        // reference button with nested selector
        [`&:hover .${getStylesRef('button')}`]: {
            visibility: "visible",
        },
    },
}));

interface IProps {
    opened: boolean,
    onClose: () => void,
    onSelectTemplate: (template: IPriceItem[]) => void
}

const PriceTemplates = (props: IProps) => {
    const {opened, onClose, onSelectTemplate} = props;
    const [ConfirmModal, confirmDrag] = useConfirmationModal({title: "Удалить шаблон цен?"})
    const {classes} = useStyles();
    const {data, isFetching} = useGetCmsPricesTemplateQuery({page: 1, pageSize: 25}, {
        skip: !opened
    })
    const [deletePriceTemplate] = useDeleteCmsPricesTemplateMutation();

    async function deleteTemplate(_id: string) {
        try {
            const isConfirmed = await confirmDrag()
            if (isConfirmed) {
                await deletePriceTemplate({_id}).unwrap();
            }
        } catch (e) {
            console.log('delete template error', e);
        }
    }

    return (
        <Modal opened={opened} onClose={onClose} centered size={"sm"} title={'Шаблон цен'}>
            {(!isFetching) ? (
                <>
                    {data?.content.map(priceItem => {
                        return (
                            <Card shadow="sm" padding="lg" radius="md" withBorder mt={10}
                                  className={classes.container}
                                  key={priceItem._id}>

                                <Flex align={'center'} justify={"space-between"}>
                                    <Text>{priceItem.title}</Text>
                                    <Button size={"xs"} variant={"light"}
                                            onClick={() => onSelectTemplate(priceItem.prices)}>Выбрать</Button>
                                </Flex>
                                <ActionIcon
                                    className={classes.button}
                                    onClick={() => deleteTemplate(priceItem._id)}
                                    style={{
                                        position: 'absolute',
                                        right: -5,
                                        top: -5
                                    }}
                                    variant={"transparent"}
                                >
                                    <IconTrashX size={'1rem'}/>
                                </ActionIcon>
                            </Card>
                        )
                    })}
                    <ConfirmModal/>
                </>
            ) : (
                <>
                    <Skeleton h={70}/>
                    <Skeleton h={70} mt={10}/>
                    <Skeleton h={70} mt={10}/>
                    <Skeleton h={70} mt={10}/>
                </>
            )}
        </Modal>
    )
}

export default PriceTemplates;
