import {useDebouncedState} from "@mantine/hooks";
import {useGetClientsQuery} from "../../api/client";
import {Autocomplete, createStyles, Divider, Group, Paper, Select, Text, TextInput} from "@mantine/core";
import {useFormContext} from "./form-context";
import {forwardRef, useEffect} from "react";
import {useCreateWhereKnownMutation, useGetWhereKnownQuery} from "../../api/whereKnown";

const useClientStyles = createStyles((theme) => ({
    dropdownItem: {
        padding: 5,
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.gray[2]
        }
    },
    rowContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: '10px 0'
    },
    divider: {
        padding: "5px 0 10px 0"
    }
}))

const Client = () => {
    const form = useFormContext();
    const {classes} = useClientStyles();
    const [value, setValue] = useDebouncedState('', 200);
    const {data = {content: []}, isFetching} = useGetClientsQuery({
        page: 1, pageSize: 5, filter:
            JSON.stringify({
                "$or": [
                    {"phone": {"$regex": "^" + value, "$options": "i"}},
                    {"name": {"$regex": "^" + value, "$options": "i"}}
                ]
            })
    }, {skip: !value});
    const {data: whereKnownData = {content: []}, isFetching: isFetchingWhereKnown} = useGetWhereKnownQuery({
        page: 1, pageSize: 999
    });
    const [createWhereKnown, result] = useCreateWhereKnownMutation();

    useEffect(() => {
        if (result.data) {
            form.setFieldValue('whereKnown', result.data.result._id)
        }
    }, [result.data])

    function onClientSelect(client: { name: string, value: string }) {
        form.setFieldValue('client', {
            name: client.name,
            phone: client.value
        })
        if (whereKnownData.content.length) {
            form.setFieldValue('whereKnown', whereKnownData.content[0]._id)
        }
    }
    function onChangeClient(v: string) {
        setValue(v)
        form.setFieldValue('client.phone', v)
    }
    return (
        <Paper p={"xs"} pt={0}>
            <div><Text>Клиент</Text></div>
            <Divider className={classes.divider} color={'gray.1'}/>
            <Autocomplete
                withinPortal
                zIndex={301}
                required={true}
                data={data.content.map(item => {
                    return {name: item.name, value: item.phone}
                })}
                limit={5}
                itemComponent={AutoCompleteItem}
                label={'Номер телефона'}
                {...form.getInputProps('client.phone')}
                onChange={onChangeClient}
                onItemSubmit={onClientSelect}
            />

            <div className={classes.rowContainer}>
                <TextInput
                    style={{width: '45%'}}
                    label="Имя клиента"
                    required={true}
                    withAsterisk
                    {...form.getInputProps('client.name')}
                />
                <Select
                    label="Откуда узнал"
                    required={true}
                    style={{width: '45%'}}
                    searchable
                    creatable
                    data={whereKnownData.content.map(item => {
                        return {label: item.name, value: item._id || ""}
                    })}
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onCreate={(query) => {
                        createWhereKnown({name: query})
                        return {value: query, label: query}
                    }}
                    {...form.getInputProps('whereKnown')}
                />
            </div>
        </Paper>
    )
}

export default Client

const AutoCompleteItem = forwardRef<HTMLDivElement, { value: string, name: string }>(
    ({value, name, ...others}: { value: string, name: string }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text>{value}</Text>
                    <Text size="xs" color="dimmed">
                        {name}
                    </Text>
                </div>
            </Group>
        </div>
    )
);