import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ActionIcon, Paper, rem, Text, Title} from "@mantine/core";
import {IconEdit} from "@tabler/icons-react";
import PageContainer from "../../../components/PageContainer";
import DefaultTable from "../../../components/DefaultTable";
import ServiceForm from "./ServiceForm";
import {IServiceCategory} from "../types";
import {
    useGetCmsServicesQuery, useReorderServiceMutation
} from "../../../api/cms/cms-services";
import DefaultTableDraggable from "../../../components/DefaultTableDraggable";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";

const Services = () => {
    const [ConfirmModal, confirmDrag] = useConfirmationModal({})
    const [isOpenedCreate, setIsOpenedCreate] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState<string | undefined>();
    const [page, setPage] = useState(1)
    const {data = {content: [], totalPages: 0}, isLoading} = useGetCmsServicesQuery({
        page,
        pageSize: 25,
        sort: {column: "position", direction: "descend"}
    });
    const [reorderService] = useReorderServiceMutation()
    const navigate = useNavigate();

    function onClick(data: IServiceCategory) {
        navigate(`${data._id}/servicePositions`)
    }

    function onEdit(data: IServiceCategory) {
        setSelectedRecord(data._id)
        setIsOpenedCreate(true)
    }

    async function onDragEnd(destination: number, source: number) {
        const isConfirmed = await confirmDrag();
        if(!isConfirmed) return;
        const destinationItem = data.content[destination];
        const sourceItem = data.content[source];
        //reorder api call
        try {
            const res = await reorderService({
                destinationId: destinationItem._id,
                sourceId: sourceItem._id
            }).unwrap()

            console.log(res)
        } catch (e) {
            console.log('reorder error', e)
        }
    }

    return (
        <PageContainer>
            <Paper mb={10}>
                <Title order={2}>Типы услуг</Title>
            </Paper>
            <DefaultTableDraggable
                data={data}
                columns={[
                    {name: '_id', label: 'Тег Услуги'},
                    {name: 'title', label: 'Название'},
                    {name: 'description', label: 'Описание'},
                    {
                        name: 'isPublished', label: 'Состояние', render: (value) => {
                            return (
                                value ? <Text c={'green'}>Опубликован</Text> : <Text c={'red'}>Скрыт</Text>
                            )
                        }
                    },
                    {
                        name: 'actions', label: 'Действия', width: rem(40), render: (value, record) => {
                            return (
                                <>
                                    <ActionIcon onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(record)
                                    }}>
                                        <IconEdit/>
                                    </ActionIcon>
                                </>
                            )
                        }
                    }
                ]}
                isFetching={isLoading}
                onChangePage={setPage}
                page={page}
                creatable
                onClickCreate={() => setIsOpenedCreate(true)}
                onRowClick={onClick}
                enableSearch
                onSearch={() => {
                }}
                onDragEnd={onDragEnd}
            />
            <ServiceForm
                opened={isOpenedCreate}
                selectedRecord={selectedRecord}
                close={() => {
                    setIsOpenedCreate(false)
                    setSelectedRecord(undefined)
                }}
            />
            <ConfirmModal/>
        </PageContainer>
    )
}
export default Services;

