import {useState} from "react";
import {useDebouncedState} from "@mantine/hooks";
import DefaultTable from "../../components/DefaultTable";
import {IDeviceAppearance} from "../../api/types";
import {useGetDeviceAppearancesQuery} from "../../api/deviceAppearance";
import PageContainer from "../../components/PageContainer";

const DeviceAppearance = () => {
    const [page, setPage] = useState(1)
    const [search, setSearch] = useDebouncedState('', 200);
    const {data = {content: [], totalPages: 0}, isFetching} = useGetDeviceAppearancesQuery({page, pageSize: 10, search})

    function onRowClick(record: IDeviceAppearance) {

    }

    return (
        <PageContainer>
            <DefaultTable
                data={data}
                columns={[{name: 'name', label: 'Наименование'}]}
                onChangePage={setPage}
                isFetching={isFetching}
                page={page}
                onSearch={setSearch}
                enableSearch={true}
                searchPlaceholder={'Поиск по всем полям'}
                onRowClick={onRowClick}
            />
        </PageContainer>
    )
}

export default DeviceAppearance;