import {
    Box,
    Button,
    Card,
    createStyles,
    Divider,
    Group,
    Modal,
    NumberInput,
    Radio,
    Skeleton,
    Text,
    Textarea
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {IconArrowDown, IconArrowUp} from "@tabler/icons-react";
import {ICashBoxTransaction, useCashBoxTransactionMutation, useGetCashCardMoneyQuery} from "../../api/finances";
import {useAppSelector} from "../../app/hooks";
import {useForm} from "@mantine/form";
import {notifications} from "@mantine/notifications";
import {isErrorWithMessage} from "../../api/api";

const useStyles = createStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    card: {
        minWidth: '30%',

    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px 0 0'
    }
}));

const CashBox = () => {
    const {classes} = useStyles();
    const selectedOffice = useAppSelector(state => state.authorization.selectedOffice)
    const {data, isFetching} = useGetCashCardMoneyQuery({office: selectedOffice})
    const [modalState, setModalState] = useState<{
        opened: boolean,
        type?: 'getFromCashBox' | 'putInCashBox'
    }>({opened: false})

    return (
        <>
            <div className={classes.buttonsContainer}>
                <Button rightIcon={<IconArrowUp/>} w={150} variant={"gradient"}
                        onClick={() => setModalState({opened: true, type: 'putInCashBox'})}
                >
                    Пополнение
                </Button>
                <Button ml={15} rightIcon={<IconArrowDown/>} w={150} variant={"gradient"}
                        onClick={() => setModalState({opened: true, type: 'getFromCashBox'})}
                >
                    Выдача
                </Button>
            </div>
            <Divider my={20}/>
            {!data || isFetching ? (
                <>
                    <Skeleton height={30} mt={6} radius="xl"/>
                    <Skeleton height={30} mt={6} radius="xl"/>
                    <Skeleton height={30} mt={6} radius="xl"/>
                </>
            ) : (
                <Box px={20}>
                    <div className={classes.container}>
                        <StatCard value={data.cash} label={'Безнал'}/>
                        <StatCard value={data.transfer} label={'Безнал к.'}/>
                        <StatCard value={data.card} label={'Карта'}/>
                    </div>
                    <Box mt={15}>
                        <StatCard value={data.sum} label={'Сумма в кассе'}/>
                    </Box>

                </Box>
            )}
            <FinancesForm
                type={modalState.type}
                opened={modalState.opened}
                onClose={() => setModalState({opened: false})}
            />
        </>
    )
}

export default CashBox


const StatCard = ({value, label}: { value: number, label: string }) => {
    const {classes} = useStyles();
    return (
        <Card
            className={classes.card}
            withBorder
            radius="md"
            padding="xl"
            shadow={"xs"}
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            })}
        >
            <Text fz="xs" tt="uppercase" fw={700} c="dimmed" size={"xs"}>
                {label}
            </Text>
            <Divider my={10}/>
            <Text fz="lg" fw={500} c={'green'}>
                {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(value)}
            </Text>
        </Card>
    )
}

const FinancesForm = (props: {
    opened: boolean,
    onClose: () => void,
    type?: 'getFromCashBox' | 'putInCashBox'
}) => {
    const {selectedOffice, user: {userId}} = useAppSelector(state => state.authorization)
    const [createTransaction] = useCashBoxTransactionMutation();
    const form = useForm<ICashBoxTransaction>({
        initialValues: {
            description: '',
            paymentType: 'cash',
            type: "",
            manager: userId || "",
            office: selectedOffice,
            amount: 0,
        },
        validate: {
            amount: (value) => value <= 0 ? 'Сумма должна быть больше 0' : null
        }
    })

    async function onSubmitForm(values: ICashBoxTransaction) {
        try {
            await createTransaction(values).unwrap()
            props.onClose()
            notifications.show({
                color: 'green',
                message: props.type === 'getFromCashBox' ? 'Сумма списанна из кассы' : 'Баланс кассы пополнен'
            })
            form.reset();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                notifications.show({color: 'red', message: e.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }
    }

    useEffect(() => {
        if (props.opened && props.type) {
            form.setFieldValue('type', props.type)
        }
        if (selectedOffice) {
            form.setFieldValue('office', selectedOffice)
        }
    }, [props.opened, props.type, selectedOffice])


    return (
        <Modal opened={props.opened} onClose={props.onClose}
               centered
               size={'md'}
               title={props.type === 'getFromCashBox' ? 'Выдача' : 'Пополнить'}
        >
            <form onSubmit={form.onSubmit(onSubmitForm)}>
                <NumberInput
                    label={'Сумма'}
                    required
                    {...form.getInputProps('amount')}
                />
                <Textarea
                    mt={15}
                    label={'Детали операции'}
                    required
                    {...form.getInputProps('description')}
                />
                <Radio.Group
                    mt={15}
                    label={`${props.type === 'getFromCashBox' ? 'Выдача' : 'Пополнение'}`}
                    {...form.getInputProps('paymentType')}
                >
                    <Group mt="xs">
                        <Radio value="cash" label="Наличные"/>
                        <Radio value="card" label="Карта"/>
                    </Group>
                </Radio.Group>
                <Divider mt={30} mb={10}/>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Button type={'submit'} variant={'outline'}>
                        Подтвердить
                    </Button>
                </div>
            </form>
        </Modal>
    )
}
