import React, {useState} from "react";
import {Button, Modal} from "@mantine/core";

export const useConfirmationModal = ({title}: {title?: string}): [any, () => Promise<boolean>] => {
    const [promise, setPromise] = useState<{ resolve: (value: boolean) => void } | null>(null);

    const confirm = () => new Promise<boolean>((resolve) => {
        setPromise({resolve});
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };
    const ConfirmModal = () => (
        <Modal opened={promise !== null} onClose={handleClose} centered zIndex={300}
               title={title || 'Подтверждение'}
        >
            <Button onClick={handleConfirm}>Да</Button>
            <Button onClick={handleCancel} variant={"outline"} ml={5}>Нет</Button>
        </Modal>
    )

    return [ConfirmModal, confirm]
}
