import {useEffect} from "react";
import {TextInput} from "@mantine/core";
import {IOffice, useReadOfficeQuery} from "../../api/office";
import {useFormContext} from "./Offices";

const OfficeForm = (props: { recordId?: string, onSubmit: (value: IOffice) => void }) => {
    const {recordId, onSubmit} = props;
    const {
        data: officeRecord,
        isFetching: isFetchingOffice
    } = useReadOfficeQuery({_id: recordId}, {skip: !Boolean(recordId)})

    const form = useFormContext();

    useEffect(() => {
        if (officeRecord && officeRecord._id) {
            form.setValues(officeRecord)
        }
    }, [officeRecord])

    return (
        <form id={"officeForm"} onSubmit={form.onSubmit(async (values) => {
            await onSubmit(values)
        })}>
            <TextInput
                label={'Наименование'}
                required
                {...form.getInputProps('name')}
            />
            <TextInput
                label={'Адресс'}
                required
                {...form.getInputProps('address')}
            />
        </form>
    )
}
export default OfficeForm