import {api} from "./api";
import {ICrudResponse, IOutsource, IQueryParams, IOutsourceCreate} from "./types";

export const outsourceApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOutsource: build.query<{ content: IOutsource[] }, IQueryParams>({
            query: (params) => ({
                url: `/api/outsource/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Outsource', tag}],
        }),
        createOutsource: build.mutation<ICrudResponse, IOutsourceCreate>({
            query: (payload) => ({
                url: '/api/outsource/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Outsource'}]
        }),
        updateOutsource: build.mutation<ICrudResponse, IOutsource>({
            query: (payload) => ({
                url: '/api/outsource/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Outsource'}]
        }),
        readOutsource: build.query<IOutsource, { _id?: string }>({
            query: (params) => ({
                url: `/api/outsource/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Outsource', tag}],
        }),
        removeOutsource: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/outsource/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'Outsource'}]
        })
    })
})

export const {
    useCreateOutsourceMutation,
    useUpdateOutsourceMutation,
    useRemoveOutsourceMutation,
    useReadOutsourceQuery,
    useGetOutsourceQuery,
} = outsourceApi