import {Button, Flex, Modal, TextInput} from "@mantine/core";
import {useState} from "react";
import {IPriceItem} from "../types";
import {useCreateCmsPricesTemplateMutation} from "../../../api/cms/cms-prices-template";
import {isErrorWithMessage} from "../../../api/api";
import {notifications} from "@mantine/notifications";

type TProps = {
    opened: boolean,
    onClose: () => any,
    prices: IPriceItem[]
}
const CreateTemplate = (props: TProps) => {
    const {onClose, opened} = props;
    const [title, setTitle] = useState("");
    const [createPriceTemplate] = useCreateCmsPricesTemplateMutation();

    async function createTemplate() {
        try {
            await createPriceTemplate({
                title,
                prices: props.prices,
            }).unwrap();
            notifications.show({color: 'green', message: "Шаблон создан"})
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    return (
        <Modal opened={opened} onClose={onClose} centered>
            <TextInput
                autoFocus={true}
                label={'Название шаблона'}
                value={title}
                required={true}
                onChange={e => setTitle(e.target.value)}
            />
            <Flex justify={'flex-end'} py={10}>
                <Button onClick={createTemplate}>Создать</Button>
            </Flex>
        </Modal>
    )
}

export default CreateTemplate;
