import {lazy, Suspense} from "react";
import Incomes from "./Incomes";
import {createStyles, Loader, Paper, SimpleGrid} from "@mantine/core";

const WhereKnownPie = lazy(() => import("./WhereKnownPie"))
const IncomeByMonths = lazy(() => import("./IncomeByMonths"))

const useStyles = createStyles(() => ({
    container: {
        padding: '10px 20px 0 0'
    }
}))
const Analytics = () => {
    const {classes} = useStyles();
    return (
        <div className={classes.container}>
            <Incomes/>
            <SimpleGrid cols={3} mt={30} breakpoints={[{maxWidth: 'md', cols: 1, spacing: 'sm'}]}>

                <Paper p={20} sx={{gridColumn: '1 / span 2', height: 500}}>
                    <Suspense fallback={<Loader variant="bars"/>}>
                        <IncomeByMonths/>
                    </Suspense>
                </Paper>
                <Paper p={20} sx={{height: 500}}>
                    <Suspense fallback={<Loader variant="bars"/>}>
                        <WhereKnownPie/>
                    </Suspense>
                </Paper>
            </SimpleGrid>

        </div>
    )
}
export default Analytics;