import {api} from "./api";
import {IOrderHistory} from "./types";

export const executorApi = api.injectEndpoints({
    endpoints: (build) => ({
        readOrderHistory: build.query<IOrderHistory, { orderId?: string }>({
            query: (params) => ({
                url: `/api/orderLog/read`,
                method: "POST",
                body: {
                    orderId: params.orderId
                }
            }),
            providesTags: (result, error, tag) => [{type: 'OrderHistory', tag}]
        }),
    })
})

export const {
    useReadOrderHistoryQuery,
    useLazyReadOrderHistoryQuery
} = executorApi