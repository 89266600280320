import {createSlice} from '@reduxjs/toolkit';

export interface AppState {
    orderHistory: {
        opened: boolean,
        orderId?: string,
    },
    isOpenCreateOrder: boolean
}

const initialState: AppState = {
    isOpenCreateOrder: false,
    orderHistory: {
        opened: false,
    }
}


export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        toggleCreateOrder: state => {
            state.isOpenCreateOrder = !state.isOpenCreateOrder
        },
        openOrderHistory: (state, action) => {
            state.orderHistory = {
                opened: true,
                orderId: action.payload
            }
        },
        closeOrderHistory: state => {
            state.orderHistory = {
                opened: false
            }
        }
    }
})

export const {toggleCreateOrder, openOrderHistory, closeOrderHistory} = orderSlice.actions
export default orderSlice.reducer