import React, {useEffect, useState} from "react";
import {SimpleGrid, createStyles} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useAppSelector} from "../../app/hooks";
import {useGetDashboardDataQuery} from "../../api/dashboard";
import SingleCard from "./SingleCard";
import {IFilterTypes} from "./Home";

const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1) calc(${theme.spacing.xl} * 1.5)`,
        [theme.fn.smallerThan('md')]: {
            padding: `calc(${theme.spacing.sm} * 1)`,
        },
    }
}));
const Statistics = ({onChangeOrdersListFilter, ordersListFilter, readyDate}: {
    onChangeOrdersListFilter: (type: IFilterTypes) => void,
    ordersListFilter: IFilterTypes,
    readyDate: {"$gt": string, "$lt": string}
}) => {
    const {classes} = useStyles();
    const [stat, setStat] = useState({
        inProgress: {
            value: 0,
            title: 'В работе',
            icon: 'inProgress',
            diff: 0
        },
        payed: {
            value: 0,
            title: 'Выданные',
            diff: 0,
            icon: 'payed'
        },
        cashBox: {
            value: 0,
            title: 'Касса',
            diff: 0,
            icon: 'cashBox'
        },
        estimatedIncome: {
            value: 0,
            title: 'К Оплате',
            diff: 0,
            icon: 'estimatedIncome'
        },
        income: {
            value: 0,
            title: 'Прибыль',
            diff: 0,
            icon: 'income'
        }
    })
    const selectedOffice = useAppSelector(state => state.authorization.selectedOffice)
    const {data, isFetching} = useGetDashboardDataQuery({office: selectedOffice, readyDate});
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const isLandscape = useMediaQuery('(orientation: landscape)')

    useEffect(() => {
        if (data) {
            setStat(prevState => {
                return {
                    inProgress: {
                        ...prevState.inProgress,
                        value: data.inProgress
                    },
                    payed: {
                        ...prevState.payed,
                        value: data.payed.count
                    },
                    cashBox: {
                        ...prevState.cashBox,
                        value: data.cashBox
                    },
                    estimatedIncome: {
                        ...prevState.estimatedIncome,
                        value: data.estimatedIncome
                    },
                    income: {
                        ...prevState.income,
                        value: data.payed.income
                    }
                }
            })
        }
    }, [JSON.stringify(data)])

    return (
        <div className={classes.root}>
            <SimpleGrid
                cols={5}
                breakpoints={[{maxWidth: 'md', cols: 2}]}
            >
                <SingleCard
                    diff={0}
                    title={stat.inProgress.title}
                    icon={"inProgress"}
                    value={stat.inProgress.value}
                    isLoading={isFetching}
                    spanNumber={isSmallScreen ? 1 : 1}
                    type={'number'}
                    iconColor={'violet'}
                    active={ordersListFilter === 'inProgress'}
                    onClick={() => onChangeOrdersListFilter('inProgress')}
                    isVisible={true}
                />
                <SingleCard
                    diff={0}
                    title={stat.estimatedIncome.title}
                    icon={"estimatedIncome"}
                    value={stat.estimatedIncome.value}
                    isLoading={isFetching}
                    type={'currency'}
                    spanNumber={isSmallScreen ? 1 : 1}
                    iconColor={'orange'}
                    active={ordersListFilter === 'waitingForPayment'}
                    onClick={() => onChangeOrdersListFilter('waitingForPayment')}
                    isVisible={false}
                />
                <SingleCard
                    diff={0}
                    title={stat.payed.title}
                    icon={"payed"}
                    value={stat.payed.value}
                    isLoading={isFetching}
                    type={'number'}
                    spanNumber={isSmallScreen ? 1 : 1}
                    iconColor={'green'}
                    active={ordersListFilter === 'payed'}
                    onClick={() => onChangeOrdersListFilter('payed')}
                    isVisible={true}
                />
                <SingleCard
                    diff={0}
                    title={stat.cashBox.title}
                    icon={"cashBox"}
                    value={stat.cashBox.value}
                    isLoading={isFetching}
                    type={'currency'}
                    spanNumber={isSmallScreen ? 1 : 1}
                    iconColor={'blue'}
                    isVisible={false}
                />
                <SingleCard
                    diff={0}
                    title={stat.income.title}
                    icon={"income"}
                    value={stat.income.value}
                    isLoading={isFetching}
                    type={'currency'}
                    spanNumber={isSmallScreen ? isLandscape ? 2 : 2 : 1}
                    iconColor={'red'}
                    isVisible={false}
                />
            </SimpleGrid>
        </div>
    )
}
export default Statistics;