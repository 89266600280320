import React, {useEffect} from 'react';
import {MantineProvider} from '@mantine/core';
import {Notifications} from '@mantine/notifications';
import {Provider as HttpProvider} from 'use-http';
import {useRoutes} from "./routes";
import Layout from "./components/layout/Layout";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {setLoadingApp} from "./app/app.slice";
import {useGetUserDetailsQuery} from "./pages/authorization/authService";
import {clearAuthorization} from "./pages/authorization/authorization.slice";
import './App.css';
import {useNavigate} from "react-router-dom";

function App() {
    const colorScheme = useAppSelector(state => state.app.colorScheme);
    const {userToken, user: {userId}} = useAppSelector(state => state.authorization)
    const routes = useRoutes(Boolean(userToken));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {data, isFetching, isError} = useGetUserDetailsQuery(userId, {
        pollingInterval: userToken ? 900000 : undefined
    })

    useEffect(() => {
        if (isFetching) {
            dispatch(setLoadingApp(isFetching))
            return
        }

        if (!data || isError) {
            dispatch(clearAuthorization())
            navigate("/")
        }

        dispatch(setLoadingApp(isFetching))
    }, [data, isFetching, isError, dispatch])
    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                colorScheme,
                colors: {
                    'brown': ['#EFEBE9', '#D7CCC8', '#BCAAA4', '#A1887F', '#8D6E63', '#795548', '#6D4C41', '#5D4037', '#4E342E', '#3E2723',]
                }
            }}
        >
            <HttpProvider>
                <Layout isAuthenticated={Boolean(userToken)}>
                    {routes}
                </Layout>
            </HttpProvider>
            <Notifications
                position={"bottom-left"}
            />
        </MantineProvider>
    );
}

export default App;
