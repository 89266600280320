import {useFormContext} from "./form-context";
import {createStyles, Divider, NumberInput, Paper, Text, Textarea} from "@mantine/core";

const useStyles = createStyles(() => ({
    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    }
}))
const ExpensesForm = () => {
    const form = useFormContext();
    const {classes} = useStyles();
    return (
        <Paper mt={30}>
            <Text>Расход</Text>
            <Divider mb={20}/>
            <div className={classes.containerRow}>
                <NumberInput
                    label={'Сумма'}
                    required
                    {...form.getInputProps('otherExpenses')}
                />
                <Textarea
                    style={{width: '90%', marginLeft: 20}}
                    label={'Описание расходов'}
                    required
                    {...form.getInputProps('otherExpensesComment')}
                />
            </div>
        </Paper>
    )

}
export default ExpensesForm;