import React, {useState} from "react";
import {
    IconArrowDownRight,
    IconArrowUpRight, IconChartArrowsVertical,
    IconCoin, IconEye, IconEyeOff,
    IconFileCheck,
    IconLoader,
    IconPackageImport
} from "@tabler/icons-react";
import {ActionIcon, createStyles, Flex, Group, Paper, rem, Skeleton, Text} from "@mantine/core";

interface IStyles {
    iconColor: string,
    active: boolean,
    onClick: boolean
}

const useStyles = createStyles((theme, {iconColor, active, onClick}: IStyles) => ({
    value: {
        fontSize: rem(24),
        fontWeight: 500,
        lineHeight: 1,
        [theme.fn.smallerThan('md')]: {
            fontSize: rem(16),
        }
    },

    diff: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors[iconColor][3] : theme.colors[iconColor][4],
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    paperContainer: {
        padding: 16,
        cursor: onClick ? "pointer" : "default",
        border: `0.0625rem solid ${active ? theme.colors.green[5] : 'transparent'}`,
        [theme.fn.smallerThan('md')]: {
            padding: 8,
        }
    },

}));

interface SingleCardProps {
    title: string,
    value: number,
    icon: 'inProgress' | 'cashBox' | 'payed' | 'estimatedIncome' | 'income',
    diff: number,
    isLoading: boolean,
    type: 'currency' | 'number',
    spanNumber?: number,
    iconColor?: string,
    active?: boolean,

    onClick?(): void,

    isVisible: boolean
}

const icons = {
    'cashBox': IconCoin,
    'payed': IconFileCheck,
    'inProgress': IconPackageImport,
    'estimatedIncome': IconLoader,
    'income': IconChartArrowsVertical
};
const SingleCard = (props: SingleCardProps) => {
    const [isVisible, setIsVisible] = useState(props.isVisible)
    const {classes} = useStyles({
        iconColor: props.iconColor || 'dark',
        active: Boolean(props.active),
        onClick: Boolean(props.onClick)
    });
    const {icon, diff, title, value, isLoading, type, spanNumber = 1} = props;

    const DiffIcon = diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    const Icon = icons[icon];

    const formattedValue = type === 'currency' ?
        new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(value) :
        value

    return (
        <Paper radius="md" style={{gridColumn: `span ${spanNumber}`}}
               className={classes.paperContainer}
               onClick={props.onClick}
        >
            {isLoading ? (
                <>
                    <Skeleton height={15} mt={6} radius="xl"/>
                    <Skeleton height={15} mt={6} radius="xl"/>
                    <Skeleton height={15} mt={6} radius="xl"/>
                </>
            ) : (
                <>
                    <Group position="apart">
                        <Text size="xs" color="dimmed" className={classes.title}>
                            {title}
                        </Text>
                        <Icon className={classes.icon} size="1.4rem" stroke={1.5}/>
                    </Group>
                    <Group align="flex-end" spacing="xs" mt={25}>
                        <Flex justify={"space-between"} w={'100%'}>
                            <Text className={classes.value}
                                  style={{
                                      filter: isVisible ? "blur(0px)" : "blur(10px)"
                                  }}
                            >{formattedValue}</Text>
                            <ActionIcon
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsVisible(prev => !prev)
                                }}
                            >
                                {isVisible ? <IconEye/> : <IconEyeOff/>}
                            </ActionIcon>
                        </Flex>
                        {diff > 0 && (
                            <Text color={diff > 0 ? 'teal' : 'red'} fz="sm" fw={500}
                                  className={classes.diff}>
                                <span>{diff}%</span>
                                <DiffIcon size="1rem" stroke={1.5}/>
                            </Text>
                        )}
                    </Group>
                    {diff > 0 && (
                        <Text fz="xs" c="dimmed" mt={7}>
                            Compared to previous month
                        </Text>
                    )}
                </>
            )}
        </Paper>
    )
}
export default SingleCard