import {DatePickerInput} from "@mantine/dates";
import React, {useState} from "react";
import {useGetFinancesQuery} from "../../api/finances";
import {useAppSelector} from "../../app/hooks";
import {IconCalendar} from "@tabler/icons-react";
import {Button, createStyles, Divider} from "@mantine/core";
import DefaultTable from "../../components/DefaultTable";
import dayjs from "dayjs";
import {IFinances} from "../../api/types";

const transactionTypeLocales = {
    orderPay: 'Оплата заказа',
    prepayment: 'Предоплата заказа',
    getFromCashBox: 'Выдача наличных',
    putInCashBox: 'Пополнение кассы'
};
const paymentTypeLocales = {
    card: 'безнал',
    cash: 'нал',
    transfer: 'нал к.'
};
const defaultDates = [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
]

function renderDetails(record: IFinances) {
    const {typeOfOperation: {paymentType, orderId}} = record;
    return (
        <>
            {transactionTypeLocales[record.typeOfOperation.name as (keyof typeof transactionTypeLocales)]}&nbsp;
            {orderId && (<u style={{cursor: 'pointer'}}>№ {orderId.orderNo}</u>)}&nbsp;
            {paymentTypeLocales[paymentType as (keyof typeof paymentTypeLocales)]}
        </>
    )
}

const useStyles = createStyles(() => ({
    container: {
        padding: '10px 20px 0 0'
    }
}))

const HistoryTransactions = () => {
    const [search, setSearch] = useState('')
    const [dateFilter, setDateFilter] = useState<{ "$lt": string, "$gt": string } | undefined>()
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState<{ column: string, direction: string }>({column: 'createdAt', direction: 'descend'})
    const {selectedOffice} = useAppSelector(state => state.authorization)
    const {data, isFetching} = useGetFinancesQuery({
        filter: {office: selectedOffice, createdAt: dateFilter},
        sort,
        page: page,
        pageSize: 10,
        search
    })
    const {classes} = useStyles();

    return (
        <div className={classes.container}>
            <DateSelection applyFilter={(value) => {
                setDateFilter({
                    "$gt": dayjs(value[0] || defaultDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    "$lt": dayjs(value[1] || defaultDates[1]).format('YYYY-MM-DDTHH:mm:ss.SSS')
                })
            }}/>
            <Divider my={15}/>
            <DefaultTable
                isFetching={isFetching}
                columns={[
                    {
                        name: 'createdAt',
                        label: 'Дата',
                        sortable: true,
                        render: (value) => dayjs(value).format('DD-MM-YYYY'),
                        width: '150px'
                    },
                    {
                        name: 'amount',
                        label: 'Сумма',
                        render: (value) => new Intl.NumberFormat('ru-RU', {
                            style: 'currency',
                            currency: 'RUB'
                        }).format(value)
                    },
                    {name: 'manager', label: 'Менеджер', render: (value) => value?.name},
                    {name: 'details', label: 'Детали', render: (value, record) => renderDetails(record)},
                    {name: 'description', label: 'Описание'},
                ]}
                data={data || {content: [], totalPages: 0}}
                onChangePage={setPage}
                page={page}
                sort={sort}
                setSort={(column: string) => {
                    setSort(prevState => {
                        return {
                            column,
                            direction: column === prevState.column ?
                                prevState.direction === 'ascend' ? 'descend' : 'ascend' : 'ascend'
                        }
                    })
                }}
                enableSearch={true}
                searchPlaceholder={'Поиск по менеджерам и описанию'}
                onSearch={setSearch}
            />
        </div>
    )
}

export default HistoryTransactions


const DateSelection = (props: { applyFilter: (param: [Date | null, Date | null]) => void }) => {
    const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <DatePickerInput
                icon={<IconCalendar size="1.1rem" stroke={1.5}/>}
                type="range"
                placeholder="Выбирите интервал времени"
                value={date}
                onChange={setDate}
                maw={400}
                locale={'ru'}
            />
            <Button ml={15}
                    variant={"outline"}
                    disabled={Boolean(!date[0] && !date[1])}
                    onClick={() => props.applyFilter(date)}>
                Применить
            </Button>
        </div>
    )
}
